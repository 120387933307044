import styled from "styled-components";
import { StylesProps } from './reminderCategory.interfaces';

export const CategoryIcon = styled.img`
  margin-right: 6px;
  width: 32px;
  height: 32px;
`;

export const CategoryTitle = styled.p`
  height: 22px;
  color: ${(props: StylesProps) => props.itemKey === +props.selectedItem ? "#ffffff" : '#3c3c3c'};
  font-family: "Avenir Next";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
`;
