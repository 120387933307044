import { takeEvery, put, call } from "redux-saga/effects";

import { fetchUserDataAsync } from "./../actions/userData.actions";

import usersAPI from "../../../services/usersApi";

export async function fetchUserData() {
  const userToken = localStorage.getItem("token");

  const userData = await usersAPI.get("/me/profile", {
    headers: { Authorization: `Bearer ${userToken}` },
  });

  if (userData) {
    return await userData.data;
  } else {
    return Promise.reject();
  }
}

export function* workerFetchUserData() {
  try {
    const userData = yield call(fetchUserData);

    yield put(fetchUserDataAsync.success(userData));
  } catch (error) {
    yield put(fetchUserDataAsync.failure(error.response.status.toString()));
  }
}

export function* userDataSaga() {
  yield takeEvery(fetchUserDataAsync.request, workerFetchUserData);
}
