import React, { useState } from "react";

import { ReminderTypes } from "../reminderTypes/reminderTypes.component";
import { ReminderCategoryProps } from "./reminderCategory.interfaces";
import {
  ReminderWrapper,
  ReminderIcon,
  ReminderName,
  MoreArrow,
} from "./reminderCategory.styles";
import moreArrowDown from "../../../../assets/svg/moreArrowDown.svg";
import moreArrowUp from "../../../../assets/svg/moreArrowUp.svg";

export const ReminderCategory: React.FC<ReminderCategoryProps> = (props) => {
  const [isTypesVisible, setIsTypesVisible] = useState(false);

  const onReminderClick = () => {
    if (!isTypesVisible) {
      props.onReminderClick(props.id);
    }

    setIsTypesVisible(!isTypesVisible);
  };

  return (
    <>
      <ReminderWrapper onClick={onReminderClick}>
        <ReminderIcon src={props.icon} alt="reminder-icon" />
        <ReminderName>{props.name}</ReminderName>
        <MoreArrow
          src={isTypesVisible ? moreArrowUp : moreArrowDown}
          alt="arrow-down"
        />
      </ReminderWrapper>
      {isTypesVisible && (
        <ReminderTypes
          reminderCategory={props.reminderCategory}
          onTypeClick={props.onTypeClick}
        />
      )}
    </>
  );
};
