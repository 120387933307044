import { takeEvery, put, call } from "redux-saga/effects";
import {
  fetchRemindersCategoriesAsync,
  fetchRemindersCostAsync,
  fetchRemindersListAsync,
} from "../actions/remindersList.actions";

import API from "../../../services/api";

export async function fetchRemindersCategories() {
  const userToken = localStorage.getItem("token");

  const remindersCategories = await API.get("/categories", {
    headers: { Authorization: `Bearer ${userToken}` },
  });

  if (remindersCategories) {
    return await remindersCategories.data;
  } else {
    return Promise.reject();
  }
}

export async function fetchRemindersCost() {
  const userToken = localStorage.getItem("token");

  const remindersCost = await API.get("/reminders/cost", {
    headers: { Authorization: `Bearer ${userToken}` },
  });

  if (remindersCost) {
    return await remindersCost.data;
  } else {
    return Promise.reject();
  }
}

export async function fetchRemindersList() {
  const userToken = localStorage.getItem("token");

  const remindersList = await API.get("/reminders", {
    headers: { Authorization: `Bearer ${userToken}` },
  });

  if (remindersList) {
    return await remindersList.data;
  } else {
    return Promise.reject();
  }
}

export function* workerFetchRemindersCategories() {
  try {
    const remindersCategories = yield call(fetchRemindersCategories);

    yield put(fetchRemindersCategoriesAsync.success(remindersCategories));
  } catch (error) {
    yield put(fetchRemindersCategoriesAsync.failure(error.response.status.toString()));
  }
}

export function* workerFetchRemindersCost() {
  try {
    const remindersCost = yield call(fetchRemindersCost);

    yield put(fetchRemindersCostAsync.success(remindersCost));
  } catch (error) {
    yield put(fetchRemindersCostAsync.failure(error.response.status.toString()));
  }
}

export function* workerFetchRemindersList() {
  try {
    const remindersList = yield call(fetchRemindersList);

    yield put(fetchRemindersListAsync.success(remindersList));
  } catch (error) {
    yield put(fetchRemindersListAsync.failure(error.response.status.toString()));
  }
}

export function* remindersCategoriesListSaga() {
  yield takeEvery(
    fetchRemindersCategoriesAsync.request,
    workerFetchRemindersCategories
  );
}

export function* remindersCostSaga() {
  yield takeEvery(fetchRemindersCostAsync.request, workerFetchRemindersCost);
}

export function* remindersListSaga() {
  yield takeEvery(fetchRemindersListAsync.request, workerFetchRemindersList);
}
