import React from "react";
import { useHistory } from 'react-router-dom';

import { LogoContainer, LogoIcon } from "./logo.styles";
import { LogoProps } from './logo.interfaces';
import { ROUTES } from '../../shared/constants/routes';

import logo from '../../assets/svg/GR-Logo.svg'
import logoWhite from '../../assets/svg/Whitelogo@1x.svg';

export const Logo: React.FC<LogoProps> = (props) => {
  const history = useHistory();

  const onLogoClick = () => {
    if (props.isSignedIn) {
      history.push(ROUTES.remindersList);
    } else {
      history.push(ROUTES.logIn);
    }
  };

  return (
    <LogoContainer isSignedIn={props.isSignedIn} onClick={onLogoClick}>
      <LogoIcon src={props.isSignedIn ? logoWhite : logo} alt="logo" />
    </LogoContainer>
  );
};
