import React from "react";

import { CountriesListProps } from "./countriesList.interfaces";

export const CountriesList: React.FC<CountriesListProps> = (props) => {
  const countriesList = props.countries.map((country) => {
    return (
      <option key={country.id} value={country.id}>
        {props.currency ? `${country.name} - ${country.currency}` : country.name} 
      </option>
    );
  });

  return <>{countriesList}</>;
};
