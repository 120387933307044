import { monthShortcutsArray } from '../../../shared/constants/months';

export const setExpiryDateFormat = (date: string) => {
  const day = date.slice(date.length - 2);
  const month = date.slice(date.length - 5, date.length - 3);
  const year = date.slice(0, date.indexOf("-"));

  return (
    `${day} ${monthShortcutsArray[+month - 1]}
    ${year}`
  );
};
