import React from "react";

import { Header } from "../../components/header/header.component";
import { Footer } from "../../components/footer/footer.component";
import { PageWrapper } from "../../components/wrapper/wrapper.component";
import { CheckYourEmailProps } from "./checkYourEmail.interfaces";
import { Button } from '../../components/button/button.component';

import {
  MainContent,
  MainContentHeader,
  MainContentText,
} from "./checkYourEmail.styles";

export const CheckYourEmail: React.FC<CheckYourEmailProps> = (props) => {
  const bodyText = () => {
    if (props.content === "activate account") {
      return "We have sent you an email with a link to activate your account";
    } else if (props.content === "reset password") {
        return "We have sent you an email with a link to reset your password";
    }

    return;
  };

  return (
    <>
      <Header />
      <PageWrapper>
        <MainContent>
          <MainContentHeader>Check your email</MainContentHeader>
          <MainContentText>{bodyText()}</MainContentText>
          <Button text="Log in" />
        </MainContent>
      </PageWrapper>
      <Footer />
    </>
  );
};
