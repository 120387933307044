import React from "react";
import { useHistory } from "react-router-dom";

import { ReminderProps } from "./reminder.interfaces";
import {
  ReminderContainer,
  ReminderIcon,
  ReminderDetails,
  TypeTitle,
  ReminderType,
  ReminderPrice,
  TimeContainer,
  TimeValue,
  TimeTitle,
  ReminderTimeIcon,
  ActLater,
  ReminderIconDummy,
} from "./reminder.styles";
import { ROUTES } from "../../shared/constants/routes";
import timeIcon from "../../assets/svg/timeIcon.svg";
import timeIconYellow from "../../assets/svg/timeIconYellow.svg";

export const Reminder: React.FC<ReminderProps> = (props) => {
  const history = useHistory();

  const renderTime = () => {
    if (isNaN(+props.time)) {
      return <ActLater>{props.time}</ActLater>;
    }

    const renderDate = () => {
      if(+props.time <= 10) {
        return  <ReminderTimeIcon src={timeIcon} alt="time"/>
      } else if(+props.time > 10 && +props.time <= 45) {
        return  <ReminderTimeIcon src={timeIconYellow} alt="time"/>
      }
    }

    return (
      <TimeContainer>
        {/* <ReminderTimeIcon
          src={+props.time <= 10 ? timeIcon : timeIconYellow}
          alt="time"
        /> */}
        {renderDate()}
        <TimeValue>{props.time}</TimeValue>
        <TimeTitle time={props.time}>DAYS</TimeTitle>
      </TimeContainer>
    );
  };

  const onReminderClick = () => {
    history.push(ROUTES.editReminder.route(props.id));
  };

  return (
    <ReminderContainer onClick={onReminderClick}>
      {props.icon ? <ReminderIcon src={props.icon} /> : <ReminderIconDummy />}
      <ReminderDetails>
        <TypeTitle>{props.reminderType || "TYPE"}</TypeTitle>
        <ReminderType>
          {props.reminderProvider === "Other"
            ? props.otherProvider
            : props.reminderProvider}
        </ReminderType>
        {props.cost && props.cost !== "0" && props.cost !== "0.00" && (
          <ReminderPrice>
            {props.cost} {props.currency} per year
          </ReminderPrice>
        )}
      </ReminderDetails>
      {renderTime()}
    </ReminderContainer>
  );
};
