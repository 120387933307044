import React from 'react';
import Modal from 'react-modal';

import { SignUpErrorProps } from './signUpError.interfaces';
import { ModalText } from './signUpError.styles';
import { Button } from '../../components/button/button.component';

import "./modal.css";

Modal.setAppElement('#root');

export const SignUpErrorModal: React.FC<SignUpErrorProps> = (props) => {
  return (
    <Modal
      isOpen={props.showModal}
      onRequestClose={props.handleCloseModal}
      className='sign-up-modal-wrapper'
    >
      <ModalText>{props.bodyText}</ModalText>
      <Button text="Log in" handleCloseModal={props.handleCloseModal} />
    </Modal>
  );
};
