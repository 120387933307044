import styled from "styled-components";

import { LogoProps } from './logo.interfaces';

export const LogoContainer = styled.div`
  height: ${(props: LogoProps) => props.isSignedIn ? "41px" : "52px"};
  width: ${(props: LogoProps) => props.isSignedIn ? "134px" : "165px"};
  margin-left: ${(props: LogoProps) => props.isSignedIn ? "23px" : "80px"};
  cursor: pointer;
  margin-top: ${(props: LogoProps) => props.isSignedIn ? "0" : "24px"};
`;

export const LogoIcon = styled.img`
  max-width: 100%;
`;
