import styled from "styled-components";
import { StylesProps } from "./remindersCategoriesList.interfaces";

export const List = styled.div`
  width: 100%;
  position: relative;
`;

export const AllRemindersButton = styled.div`
  border-radius: 8px;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`;

export const CategoryTitle = styled.p`
  height: 22px;
  color: ${(props: StylesProps) =>
    props.selected === "all" ? "#ffffff" : "#3c3c3c"};
  font-family: "Avenir Next";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
`;

export const ArrowLeft = styled.div`
  width: 34px;
  height: 100%;
  background: linear-gradient(
    90deg,
    #f2f2f2 0%,
    rgba(255, 255, 255, 0.0001) 100%
  );
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const ArrowRight = styled.div`
  width: 34px;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.0001) 0%,
    #f2f2f2 46.63%
  );
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

export const Arrow = styled.img``;
