import React from "react";

import { ReminderCategoryProps } from "./reminderCategory.interfaces";
import { CategoryIcon, CategoryTitle } from "./reminderCategory.styles";

export const ReminderCategory: React.FC<ReminderCategoryProps> = (props) => {
  return (
    <>
      <CategoryIcon src={props.icon} alt="reminder category" />
      <CategoryTitle itemKey={props.itemKey} selectedItem={props.selectedItem}>
        {props.title}
      </CategoryTitle>
    </>
  );
};
