import styled from "styled-components";
import { Link } from "react-router-dom";

import { StylesProps } from "./changePassword.interfaces";

export const PageWrapper = styled.div`
  background-color: #f2f2f2;
  padding: 0 74px;
  min-height: 100vh;
  padding-bottom: 35px;
  padding-top: 64px;
`;

export const BackLink = styled(Link)`
  display: block;
  height: 26px;
  color: #00d6c2;
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  margin-bottom: 4px;
`;

export const PageTitle = styled.p`
  height: 48px;
  color: #00295b;
  font-family: Avenir;
  font-size: 45px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 48px;
`;

export const SeparateLine = styled.div`
  box-sizing: border-box;
  height: 2px;
  width: 100%;
  border: 1px solid #d2d2d2;
  margin: 41.75px 0 39.25px;
`;

export const PasswordForm = styled.form`
  width: 476px;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 33px 35px 46px;
  box-sizing: border-box;
  margin-bottom: 109px;
`;

export const FormFieldContainer = styled.div`
  margin-bottom: 32px;
  width: 100%;
  position: relative;
`;

export const FormLabel = styled.label`
  display: block;
  margin: 0 0 4px 8px;
  height: 22px;
  color: ${(props: StylesProps) =>
    props.validationError ? "#E51450" : "#444444"};
  font-family: "Avenir Next";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
`;


export const FormField = styled.input`
  width: 100%;
  box-sizing: border-box;
  height: 48px;
  border: ${(props: StylesProps) =>
    props.validationError ? "1px solid #E51450" : "1px solid #b4b3b3"};
  border-radius: 8px;
  background-color: #ffffff;
  padding-left: 14px;
  font-family: "Avenir Next";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 25px;
  outline: none;
  color: #444444;

  &:focus {
    border-color: ${(props: StylesProps) =>
      props.validationError ? "#E51450" : "#00d6c2"};
  }

  &::placeholder {
    color: #949594;
    font-family: "Avenir Next";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding-top: 8px;
`;

export const SubmitButton = styled.input`
  height: 46px;
  width: 164px;
  border-radius: 6px;
  background-color: #00d6c2;
  color: #031E52;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  border: none;
  outline: none;
  margin-left: 13px;
  cursor: pointer;
`;

export const CancelButton = styled.button`
  box-sizing: border-box;
  background-color: #ffffff;
  height: 48px;
  width: 151px;
  border: 2px solid #b4b3b3;
  border-radius: 6px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  outline: none;
  cursor: pointer;
`;

export const CancelButtonText = styled.span`
  height: 22px;
  width: 135px;
  color: #626262;
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
`;

export const ErrorMessage = styled.p`
  margin: 5px 0 0 4px;
  height: 15px;
  width: 357px;
  color: #e51450;
  font-family: "Avenir Next";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.33px;
  line-height: 15px;
`;
