import { createReducer } from "typesafe-actions";
import {
  fetchRemindersCategoriesAsync,
  fetchRemindersCostAsync,
  fetchRemindersListAsync,
  removeRemindersData,
} from "../actions/remindersList.actions";
import { StoreData } from "./reducers.interfaces";
import { ReminderCategoryInterface } from "../../../shared/interfaces/reminderCategory.interface";
import { RemindersCost } from "../../../shared/interfaces/remindersCost.interface";
import { RemindersList } from "../../../shared/interfaces/remindersList.interface";

export const initialState = {
  remindersListStore: [],
  remindersCost: {},
  remindersList: {},
  errors: "",
};

export const remindersListReducer = createReducer(initialState)
  .handleAction(fetchRemindersCategoriesAsync.request, (state: StoreData) => ({
    ...state,
  }))
  .handleAction(
    fetchRemindersCategoriesAsync.success,
    (state: StoreData, action: { payload: ReminderCategoryInterface[] }) => {
      return { ...state, remindersListStore: action.payload };
    }
  )
  .handleAction(
    fetchRemindersCategoriesAsync.failure,
    (state: StoreData, action: { paylaod: string }) => ({
      ...state,
      errors: action.paylaod,
    })
  )
  .handleAction(fetchRemindersCostAsync.request, (state: StoreData) => ({
    ...state,
  }))
  .handleAction(
    fetchRemindersCostAsync.success,
    (state: StoreData, action: { payload: RemindersCost }) => {
      return { ...state, remindersCost: action.payload };
    }
  )
  .handleAction(
    fetchRemindersCostAsync.failure,
    (state: StoreData, action: { payload: string }) => ({
      ...state,
      errors: action.payload,
    })
  )
  .handleAction(fetchRemindersListAsync.request, (state: StoreData) => ({
    ...state,
  }))
  .handleAction(
    fetchRemindersListAsync.success,
    (state: StoreData, action: { payload: RemindersList }) => {
      return { ...state, remindersList: action.payload };
    }
  )
  .handleAction(
    fetchRemindersListAsync.failure,
    (state: StoreData, action: { payload: string }) => ({
      ...state,
      errors: action.payload,
    })
  )
  .handleAction(removeRemindersData, (state: StoreData) => ({
    ...state,
    remindersListStore: [],
    remindersCost: {},
    remindersList: {},
    errors: "",
  }));
