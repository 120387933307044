import styled from "styled-components";

import { StylesProps } from "./countrySelect.interfaces";

export const ModalHeader = styled.div`
  color: #00295b;
  width: 312px;
  height: 36px;
  font-family: "Avenir Next";
  font-size: 26px;
  font-weight: bold;
  line-height: 30px;
  margin-bottom: 12px;
  margin-top: 27px;
  text-align: center;
`;

export const ModalBody = styled.div`
  width: 312px;
  height: 66px;
  color: #00295b;
  font-family: "Avenir Next";
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 18px;
  text-align: center;
`;

export const CountryForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// export const FormLabel = styled.label`
//   display: block;
//   height: 19px;
//   color: ${(props: StylesProps) =>
//     props.validationError ? "#E51450" : "#444444"};
//   font-family: "Avenir Next";
//   font-size: 14px;
//   font-weight: 600;
//   letter-spacing: 0;
//   line-height: 19px;
//   margin: 0 0 5px 8px;
// `;

export const FormFieldContainer = styled.div`
  width: 288px;
  position: ${(props: StylesProps) => (props.rel ? "relative" : "static")};
  margin-bottom: 12px;

  &:last-of-type {
    margin-bottom: 31.25px;
  }
`;

export const FormField = styled.input`
  width: 288px;
  height: 48px;
  border: ${(props: StylesProps) =>
    props.validationError ? "1px solid #E51450" : "1px solid #b4b3b3"};
  border-radius: 8px;
  background-color: #ffffff;
  color: #444440;
  font-family: "Avenir Next";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  outline: none;
  box-sizing: border-box;
  padding: 14px 0 15px 16px;
  margin-botto: 12px;

  &:focus {
    border-color: ${(props: StylesProps) =>
      props.validationError ? "#E51450" : "#00d6c2"};
  }

  &::placeholder {
    color: #949594;
    font-family: "Avenir Next";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
  }
`;

export const FormSelectField = styled.select`
  width: 288px;
  height: 48px;
  border: ${(props: StylesProps) =>
    props.validationError ? "1px solid #E51450" : "1px solid #b4b3b3"};
  border-radius: 8px;
  background-color: #ffffff;
  color: ${(props: StylesProps) =>
    props.countryField && props.countryField !== "DEFAULT"
      ? "#444440"
      : "#949594"};
  font-family: "Avenir Next";
  font-size: ${(props: StylesProps) =>
    props.countryField && props.countryField !== "DEFAULT" ? "16px" : "14px"};
  letter-spacing: 0;
  line-height: 19px;
  padding: 0 20px 0 16px;
  outline: none;
  appearance: none;
`;

export const SelectArrow = styled.div`
  height: 0;
  width: 0;
  border-style: solid;
  border-width: 8px 6px 0 6px;
  border-color: #3c3c3c transparent transparent transparent;
  position: absolute;
  top: 21px;
  right: 21px;
`;

export const SubmitButtom = styled.input`
  width: 100%;
  height: 44px;
  border-radius: 6px;
  background: linear-gradient(45deg, #00c8aa 0%, #00dac2 100%);
  border: none;
  cursor: pointer;
  outline: none;
  color: #ffffff;
  font-family: "Avenir Next";
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 27px;
`;

export const LocationIcon = styled.img`
  margin-top: 59px;
`;
