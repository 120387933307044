import styled from "styled-components";

export const ReminderWrapper = styled.div`
  height: 105px;
  width: 475px;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

export const ReminderIcon = styled.img`
  height: 48px;
  width: 48px;
  margin-right: 24px;
  margin-left: 17px;
`;

export const ReminderName = styled.p`
  height: 25px;
  width: 186px;
  color: #3c3c3c;
  font-family: "Avenir Next";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
`;

export const MoreArrow = styled.img`
  height: 24px;
  width: 24px;
  position: absolute;
  right: 15px;
`;
