import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Header } from "../../components/header/header.component";
import { Footer } from "../../components/footer/footer.component";
import { PageWrapper } from "../../components/wrapper/wrapper.component";
import { Button } from "../../components/button/button.component";
import { SocialButton } from "../../components/socialButton/socialButton.component";
import {
  SignUpWrapper,
  SignUpContainer,
  SignUpHeader,
  SignUpText,
  SignUpForm,
  CheckboxContainer,
  SignUpCheckbox,
  CheckboxLabel,
  LineContainer,
  Line,
  LineContainerText,
  FormFieldContainer,
  FormLabel,
  FormField,
  FormSelectField,
  Terms,
  TermsLink,
  TermsContainer,
  ErrorMesage,
  SelectArrow,
  InsideLink,
} from "./signUp.styles";
import {
  SignUpNameError,
  SignUpEmailError,
  SignUpCountryError,
  SignUpPosctodeError,
  SignUpPasswordValidation,
  SignUpConfirmPasswordValidation,
} from "../../components/formsValidation/formsValidation.component";
import facebookSignUpIcon from "../../assets/svg/facebook-sign-in.svg";
import googleSignUpIcon from "../../assets/svg/google-sign-in.svg";
import { CountriesList } from "../../components/countriesList/countriesList.component";
import { SignUpErrorModal } from "../../modals/signUpError/signUpError.component";
import { RootState } from "./signUp.interfaces";
import { fetchCountriesAsync } from "../../store/countries/actions/countries.actions";
import { ServerError } from '../../components/serverError/serverError.components';
import API from "../../services/authApi";
import { ROUTES } from "../../shared/constants/routes";

export const SignUp: React.FC = () => {
  const [emailExist, setEmailExist] = useState(true);
  const [postcodeValue, setPostcodeValue] = useState("");
  const [policy, setPolicy] = useState(true);
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const { register, handleSubmit, errors, watch, control } = useForm({
    defaultValues: {
      email: "",
      first_name: "",
      password: "",
      confirm_password: "",
      country: "DEFAULT",
      postcode: "",
      policy: false,
    },
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const countries = useSelector(
    (state: RootState) => state.countries.countries
  );

  useEffect(() => {
    if (countries.length === 0) {
      dispatch(fetchCountriesAsync.request());
    }
  }, [countries.length, dispatch]);

  const countriesError = useSelector((state: RootState) => state.countries.erorrs);

  const onFormSubmit = handleSubmit(async (data) => {
    const userData = {
      email: data.email,
      first_name: data.first_name,
      password: data.password,
      confirm_password: data.confirm_password,
      country_id: +data.country,
      postcode: data.postcode,
    };

    try {
      const response = await API.post("/register", userData);
      if (!response) {
        setEmailExist(false);
      } else {
        history.push(ROUTES.verifyEmailMessage);

        setEmailExist(true);
      }
    } catch (error) {
      let message = error.response.request.response;
      message = message.slice(message.indexOf("Sorry"), message.length - 4);

      if (message) {
        setServerErrorMessage(message);
        handleOpenModal();
      }

      setEmailExist(false);
    }
  });

  const onPostcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const postcode = event.target.value;

    if (/^[A-Za-z0-9 ]+$/.test(postcode) || !postcode) {
      setPostcodeValue(postcode);
    }
  };

  const onEmailChange = () => {
    if (!emailExist) {
      setEmailExist(true);
    }
  };

  const onPolicyClick = () => {
    setPolicy(watch("policy"));
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const renderForm = () => {
    if (+countriesError >= 500 || +countriesError === 404) {
      return <ServerError code={countriesError} />;
    }

    if (countries.length === 0) {
      return <></>;
    }

    return (
      <>
        <Header />
        <PageWrapper>
          <SignUpWrapper>
            <SignUpContainer>
              <SignUpHeader>Get started</SignUpHeader>
              <SignUpText>
                Sign up for FREE and set your reminders today. You’ll never
                overpay again.
              </SignUpText>
              <SignUpForm onSubmit={onFormSubmit}>
                {(errors.policy || !policy) && (
                  <ErrorMesage>
                    Please agree to GetReminded to store and use your personal
                    data.
                  </ErrorMesage>
                )}
                <CheckboxContainer>
                  <SignUpCheckbox
                    name="policy"
                    value=""
                    type="checkbox"
                    onClick={onPolicyClick}
                    ref={register({ required: true })}
                  />
                  <CheckboxLabel>
                    I agree to my personal data being stored and used as per the
                    GetReminded Privacy Policy
                  </CheckboxLabel>
                </CheckboxContainer>

                <SocialButton
                  social="facebook"
                  icon={facebookSignUpIcon}
                  setPrivacy={onPolicyClick}
                  privacy={watch("policy")}
                />
                <SocialButton
                  social="google"
                  icon={googleSignUpIcon}
                  setPrivacy={onPolicyClick}
                  privacy={watch("policy")}
                />
                <SocialButton
                  social="apple"
                  privacy={true}
                />
                
                <SignUpErrorModal
                  handleCloseModal={handleCloseModal}
                  handleOpenModal={handleOpenModal}
                  bodyText={serverErrorMessage}
                  showModal={showModal}
                />

                <LineContainer>
                  <Line />
                  <LineContainerText>OR</LineContainerText>
                  <Line />
                </LineContainer>

                <FormFieldContainer validationError={errors.first_name}>
                  <FormLabel validationError={errors.first_name}>
                    First name*
                  </FormLabel>
                  <FormField
                    name="first_name"
                    placeholder="First name"
                    maxLength={20}
                    validationError={errors.first_name}
                    ref={register({
                      validate: (value) => value.length >= 2,
                    })}
                  />
                  {errors.first_name && (
                    <SignUpNameError val={watch("first_name")} />
                  )}
                </FormFieldContainer>

                <FormFieldContainer
                  validationError={errors.email || !emailExist}
                >
                  <FormLabel validationError={errors.email || !emailExist}>
                    Email*
                  </FormLabel>
                  <FormField
                    name="email"
                    placeholder="Email"
                    maxLength={64}
                    onChange={onEmailChange}
                    validationError={errors.email || !emailExist}
                    ref={register({
                      validate: (value) =>
                        value.includes("@") &&
                        value.includes(".") &&
                        value.length > 0 &&
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                          value
                        ),
                    })}
                  />
                  {(errors.email && (
                    <SignUpEmailError val={watch("email")} />
                  )) ||
                    (!emailExist && (
                      <SignUpEmailError val={emailExist.toString()} />
                    ))}
                </FormFieldContainer>

                <FormFieldContainer validationError={errors.country} rel="true">
                  <FormLabel validationError={errors.country}>
                    Country*
                  </FormLabel>
                  <FormSelectField
                    name="country"
                    defaultValue={`DEFAULT`}
                    validationError={errors.country}
                    countryField={watch("country")}
                    ref={register({
                      validate: (value) => value !== "DEFAULT",
                    })}
                  >
                    <option value="DEFAULT">Please select</option>
                    <CountriesList countries={countries} />
                  </FormSelectField>
                  {errors.country && <SignUpCountryError />}
                  <SelectArrow />
                </FormFieldContainer>

                <FormFieldContainer validationError={errors.postcode}>
                  <FormLabel validationError={errors.postcode}>
                  Postcode / Zip Сode
                  </FormLabel>

                  <Controller
                    as={
                      <FormField
                        placeholder="Postcode / Zip code"
                        validationError={errors.postcode}
                        maxLength={16}
                        value={postcodeValue || ""}
                        onChange={onPostcodeChange}
                      />
                    }
                    name="postcode"
                    control={control}
                    rules={{
                      validate: (value) =>
                        (value.length >= 4 && /^[A-Za-z0-9 ]+$/.test(value)) ||
                        value.length === 0,
                    }}
                  />
                  {errors.postcode && (
                    <SignUpPosctodeError val={watch("postcode")} />
                  )}
                </FormFieldContainer>

                <FormFieldContainer validationError={errors.password}>
                  <FormLabel validationError={errors.password}>
                    Password*
                  </FormLabel>
                  <FormField
                    type="password"
                    name="password"
                    placeholder="Minimum 6 characters"
                    maxLength={20}
                    validationError={errors.password}
                    ref={register({
                      validate: (value) =>
                        value.length >= 6 &&
                        value.length <= 20 &&
                        !value.includes(" "),
                    })}
                  />
                  {errors.password && (
                    <SignUpPasswordValidation val={watch("password")} />
                  )}
                </FormFieldContainer>

                <FormFieldContainer validationError={errors.confirm_password}>
                  <FormLabel validationError={errors.confirm_password}>
                    Confirm password*
                  </FormLabel>
                  <FormField
                    type="password"
                    name="confirm_password"
                    placeholder="Confirm password"
                    maxLength={20}
                    validationError={errors.confirm_password}
                    ref={register({
                      validate: (value) =>
                        value === watch("password") && value.length !== 0,
                    })}
                  />
                  {errors.confirm_password && (
                    <SignUpConfirmPasswordValidation
                      val={watch("confirm_password")}
                    />
                  )}
                </FormFieldContainer>

                <Button reset text="Sign up" />
              </SignUpForm>
              <TermsContainer>
                <Terms firstTerm>
                  By signing up, you agree to the{" "}
                  <TermsLink href="https://www.getreminded.com/terms-of-service/">
                    Terms of Service
                  </TermsLink>
                </Terms>
                <Terms>
                  Already have an account?{" "}
                  <InsideLink to={ROUTES.logIn}>Log in</InsideLink>
                </Terms>
              </TermsContainer>
            </SignUpContainer>
          </SignUpWrapper>
        </PageWrapper>
        <Footer />
      </>
    );
  };

  return <>{renderForm()}</>;
};
