import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Header } from "../../components/header/header.component";
import { Footer } from "../../components/footer/footer.component";
import { PageWrapper } from "../../components/wrapper/wrapper.component";
import { OldVerificationLink } from "../oldVerificationLink/oldVerificationLink.component";
import { NewPasswordForm } from "../newPasswordForm/newPasswordForm.component";
import { Button } from '../../components/button/button.component';
import API from "../../services/authApi";

import { MainContent, MainContentHeader, MainContentText } from './passwordReset.styles';

export const PasswordReset: React.FC = () => {
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [activeToken, setActiveToken] = useState(true);
  const [pageLoaded, setPageLoaded] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const tryToken = async () => {
      const token = location.search.slice(location.search.indexOf("=") + 1);

      try {
        await API.post('/check-email-key', null, { headers: { Authorization: token } });

        if (token) {
          setPageLoaded(true);
        }
      } catch (error) {
        setActiveToken(false);
        setPageLoaded(true);
      }
    };

    tryToken();
  }, [location.search]);

  const onFormSubmit = async (data: {}) => {
    const token = location.search.slice(location.search.indexOf("=") + 1);

    try {
      await API.post("/confirm-password", data, {
        headers: { Authorization: token },
      });
    } catch (error) {
      console.log(error);
    }

    setChangeSuccess(true);
  };

  const renderContent = () => {
    if (changeSuccess) {
      return (
        <MainContent>
          <MainContentHeader>Success</MainContentHeader>
          <MainContentText>Your password was successfully changed</MainContentText>
          <Button text="Log in" />
        </MainContent>
      );
    }

    if (!activeToken) {
      return <OldVerificationLink buttonText="Log in" />;
    }

    return <NewPasswordForm onFormSubmit={onFormSubmit} />;
  };

  return (
    <>
      <Header />
      <PageWrapper>
        {pageLoaded && renderContent()}
      </PageWrapper>
      <Footer />
    </>
  );
};
