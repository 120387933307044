import React, { useState } from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";

import { List } from "./remindersCategoriesList.styles";
import { RemindersCategoriesListProps } from "./remindersCategoriesList.interfaces";
import {
  AllRemindersButton,
  CategoryTitle,
  ArrowLeft,
  ArrowRight,
  Arrow,
} from "./remindersCategoriesList.styles";
import arrow from "../../assets/svg/arrow.svg";
import arrowLeft from "../../assets/svg/arrowLeft.svg";
import "./reminder.css";

export const RemindersCategoriesList: React.FC<RemindersCategoriesListProps> = (
  props
) => {
  const [selectedElement, setSelectedElement] = useState("all");

  const onItemSelect = (key: any) => {
    props.getSelectedElement(key);

    setSelectedElement(key);
  };

  const remindersCategoriesList = () => {
    const categoriesList = props.renderRemindersCategoriesList();

    categoriesList?.unshift(
      <AllRemindersButton key={"all"}>
        <CategoryTitle selected={selectedElement}>All reminders</CategoryTitle>
      </AllRemindersButton>
    );

    categoriesList?.push(<div key="last" />);

    return categoriesList;
  };

  return (
    <List>
      <ScrollMenu
        data={remindersCategoriesList()}
        selected={selectedElement}
        onSelect={onItemSelect}
        arrowLeft={
          <ArrowLeft>
            <Arrow src={arrowLeft} />
          </ArrowLeft>
        }
        arrowRight={
          <ArrowRight>
            <Arrow src={arrow} />
          </ArrowRight>
        }
        hideSingleArrow={true}
        scrollBy={1}
        itemClass="item"
        itemClassActive="active-item"
        alignCenter={false}
        alignOnResize={false}
        innerWrapperClass="categories-list"
        scrollToSelected={true}
        dragging={false}
        wheel={false}
      />
    </List>
  );
};

