import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Header } from "../../components/header/header.component";
import { ReminderForm } from "../../components/reminderForm/reminderForm.component";
import { fetchReminderDataAsync } from "../../store/reminderData/actions/reminderData.actions";
import { fetchRemindersCategoriesAsync } from "../../store/remindersList/actions/remindersList.actions";
import { fetchUserDataAsync } from "../../store/userData/actions/userData.actions";
import { setExpiryDateFormat } from "../remindersList/utils/setExpiryDateFormat";
import { refreshToken } from "../../shared/utils/refreshToken";
import { RootState } from "./editReminder.interfaces";
import {
  PageWrapper,
  BackLink,
  PageHeaderContainer,
  ReminderTitle,
  CategoryIconContainer,
  CategoryIcon,
  SeparateLine,
  TimeContainer,
  TimeValue,
  TimeTitle,
  ReminderTimeIcon,
  ActLaterText,
} from "./editReminder.styles";
import { AdvertisementsList } from "./components/advertisementsList/advertisementsList.component";

import { ReminderData } from "../newReminder/newReminder.interfaces";
import { ROUTES } from "../../shared/constants/routes";
import { monthsArray } from "../../shared/constants/months";
import timeIcon from "../../assets/svg/timeIcon.svg";
import timeIconYellow from "../../assets/svg/timeIconYellow.svg";
import API from "../../services/api";

export const EditReminder: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [serverError, setServerError] = useState("");

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const reminderUrl = location.pathname.split("/");
  const reminderId = reminderUrl[reminderUrl.length - 1];

  const remindersCategories = useSelector(
    (state: RootState) => state.remindersList.remindersListStore
  );

  const userData = useSelector((state: RootState) => state.userData.userData);

  const reminderData = useSelector(
    (state: RootState) => state.reminderData.reminderData
  );

  useEffect(() => {
    if (!userData.country) {
      dispatch(fetchUserDataAsync.request());
      dispatch(fetchRemindersCategoriesAsync.request());
    }

    if (!reminderData.id && userData.country) {
      dispatch(fetchReminderDataAsync.request(reminderId));
    }

    if (userData.country) {
      setIsLoaded(true);
    }
  }, [
    dispatch,
    reminderData.id,
    reminderId,
    remindersCategories,
    userData.country,
  ]);

  const userDataError = useSelector(
    (state: RootState) => state.userData.errors
  );

  const reminderError = useSelector(
    (state: RootState) => state.reminderData.errors
  );

  const onDateInputChange = () => {
    if (serverError) {
      setServerError("");
    }
  };

  const setReminderData = (data: ReminderData) => {
    const userToken = localStorage.getItem("token");
    const monthNumber = monthsArray.indexOf(data.month) + 1;
    const date = `${data.year}-${
      monthNumber < 10 ? "0" + monthNumber : monthNumber
    }-${data.day}`;

    let reminderEditData: {};

    if (data.payment !== "DEFAULT") {
      const [frequency] = reminderData.type.cost_frequency.filter(
        (frequency) => frequency.id === +data.payment
      );

      let annualCost: string;

      if (frequency) {
        if (frequency.frequency === "Monthly") {
          annualCost = `${+data.cost * 12}`;
        } else if (frequency.frequency === "Weekly") {
          annualCost = `${+data.cost * 12 * 4}`;
        } else if (frequency.frequency === "Quarterly") {
          annualCost = `${+data.cost * 4}`;
        } else {
          annualCost = data.cost;
        }
      } else {
        annualCost = "0";
      }

      reminderEditData = {
        category_id: reminderData.category,
        type_id: data.type,
        provider_id: data.provider,
        other_provider: data.other_provider,
        expiry_date: date,
        description: data.description,
        cost: data.cost || "0.00",
        annual_cost: annualCost,
        cost_frequency: frequency,
        cost_frequency_id: frequency ? frequency.id : null,
      };
    } else {
      reminderEditData = {
        category_id: reminderData.category,
        type_id: data.type,
        provider_id: data.provider,
        other_provider: data.other_provider,
        expiry_date: date,
        description: data.description,
        cost: data.cost || "0.00",
        annual_cost: "0.00",
      };
    }

    const setReminderData = async () => {
      try {
        const resp = await API.patch(
          `/reminders/${reminderId}`,
          reminderEditData,
          {
            headers: { Authorization: `Bearer ${userToken}` },
          }
        );

        if (resp) {
          history.push(ROUTES.remindersList);
        }
      } catch (error) {
        let serverErrorMessage = error.response.data.detail.expiry_date[0];

        if (serverErrorMessage.startsWith("Date")) {
          serverErrorMessage = serverErrorMessage.slice(
            0,
            serverErrorMessage.indexOf(".") + 1
          );
        }

        setServerError(serverErrorMessage);
      }
    };

    setReminderData();
  };

  const renderHeader = () => {
    if (remindersCategories.length > 0 && reminderData.id && isLoaded) {
      const [selectedCategory] = remindersCategories.filter(
        (category) => category.id === reminderData.category
      );

      return (
        <>
          <PageHeaderContainer>
            {selectedCategory ? (
              <CategoryIconContainer>
                <CategoryIcon
                  src={selectedCategory.coloured_icon}
                  alt="reminder-category-icon"
                />
              </CategoryIconContainer>
            ) : (
              ""
            )}
            <ReminderTitle>
              {selectedCategory
                ? selectedCategory.name
                : reminderData.type.name}
            </ReminderTitle>
            {Number(reminderData.days_to_expiration) <= 45 && reminderData.days_to_expiration ? (
              <TimeContainer>
                <ReminderTimeIcon
                  src={
                    +reminderData.days_to_expiration <= 10
                      ? timeIcon
                      : timeIconYellow
                  }
                  alt="time"
                />
                <TimeValue>
                  {+reminderData.days_to_expiration < 10
                    ? "0" + reminderData.days_to_expiration
                    : reminderData.days_to_expiration}
                </TimeValue>
                <TimeTitle time={reminderData.days_to_expiration}>
                  DAYS
                </TimeTitle>
              </TimeContainer>
            ) : (
              <ActLaterText>
                {setExpiryDateFormat(reminderData.expiry_date)}
              </ActLaterText>
            )}
          </PageHeaderContainer>
          <SeparateLine />
          {(reminderData.campaign_advertisements ||
            reminderData.inactive_advertisements) && (
            <AdvertisementsList
              campaign_advertisements={reminderData.campaign_advertisements}
              inactive_advertisements={reminderData.inactive_advertisements}
              reminderId={reminderData.id}
            />
          )}
          <ReminderForm
            reminder={selectedCategory}
            selectedType={reminderData.type}
            currency={userData.country.currency}
            reminderForEdit={reminderData}
            setReminder={setReminderData}
            serverError={serverError}
            onDateInputChange={onDateInputChange}
          />
        </>
      );
    } else {
      return <></>;
    }
  };

  const checkData = () => {
    if (reminderError === "404") {
      history.push(ROUTES.remindersList);
    }

    if (userDataError) {
      const refresh = refreshToken();

      if (refresh) {
        history.push(ROUTES.logIn);
      }
    }
  };

  return (
    <>
      {checkData()}
      <Header isSignedIn />
      <PageWrapper>
        <BackLink to={ROUTES.remindersList}>Back to Reminders</BackLink>
        {renderHeader()}
      </PageWrapper>
    </>
  );
};
