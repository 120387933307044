import authAPI from "../../services/authApi";

export const refreshToken = (): boolean => {
  const userToken = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refresh");
  let expiredRefresh = false;

  if (!userToken) {
    return true;
  }

  const moveTokenToServer = async () => {
    try {
      const resp = await authAPI.post(
        "/token/refresh/",
        { refresh: refreshToken },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );

      if (resp) {
        localStorage.setItem("token", resp.data.access);
        localStorage.setItem("refresh", resp.data.refresh);
      }
    } catch (error) {
      expiredRefresh = true;

      console.log(error.response);
    }
  };

  moveTokenToServer();

  return expiredRefresh || false;
};
