import styled from "styled-components";

import { ButtonStyles } from "./socialButton.interfaces";

export const ButtonOwerflow = styled.div`
  position: absolute;
  top: 0;
  height: 44px;
  width: 370px;
  border-radius: 6px;
  display: ${(props: ButtonStyles) => props.privacy ? "none" : "static"};
`;

export const FacebookButtonOwerflow = styled.div`
  position: absolute;
  top: 393px;
  height: 44px;
  width: 370px;
  border-radius: 6px;
  display: ${(props: ButtonStyles) => props.privacy ? "none" : "static"};
  cursor: pointer;
`;

export const ButtonWrapper = styled.button`
  height: 44px;
  width: 370px;
  border-radius: 6px;
  background-color: ${(props: ButtonStyles) =>
    props.social === "facebook" ? "#0079fb" : "#DC4E41"};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(props: ButtonStyles) =>
    props.social === "facebook" ? "0" : "16px 0 16px"};
  cursor: pointer;
  outline: none;
  border: none;
  position: relative;
`;

export const ButtonBody = styled.div`
  height: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SocialIcon = styled.img`
  margin-right: 8px;
  width: ${(props: ButtonStyles) =>
    props.social === "facebook" ? "24px" : "18px"};
  height: ${(props: ButtonStyles) =>
    props.social === "facebook" ? "24px" : "18px"};
`;

export const ButtonText = styled.p`
  height: 27px;
  width: ${(props: ButtonStyles) =>
    props.social === "facebook" ? "217px" : "199.86px"};
  color: #ffffff;
  font-family: "Avenir Next";
  font-size: 20px;
  letter-spacing: -0.5px;
  line-height: 27px;
  text-align: center;
`;

export const SocialName = styled.span`
  font-weight: bold;
  text-transform: capitalize;
`;
