import styled from "styled-components";

export const ValidationMessage = styled.p`
  height: 15px;
  color: #e51450;
  font-family: "Avenir Next";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.33px;
  line-height: 15px;
  margin: 5px 0 0 4px;
`;
