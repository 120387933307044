/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import { AdvertisementsListProps } from "./advertisementsList.interfaces";
import { AdvertisementItem } from "../advertisementItem/advertisementItem.component";
import { List } from "./advertisementsList.styles";
import campaignsAPI from "../../../../services/campaignsApi";

export const AdvertisementsList: React.FC<AdvertisementsListProps> = ({
  campaign_advertisements,
  inactive_advertisements,
  reminderId,
}) => {
  const userToken = localStorage.getItem("token");

  useEffect(() => {
    const lookAdvertisement = async () => {
      const campaign_ids = campaign_advertisements!.map((elem) => elem.campaign);

      await campaignsAPI.post(
        "/app-impression",
        {
          event_type: "web_app_ad_view",
          campaign_ids: [...campaign_ids],
          reminder_id: Number(reminderId),
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
    }

    if (campaign_advertisements) {
      lookAdvertisement();
    }
  }, []);

  if (campaign_advertisements) {
    const advertisements = campaign_advertisements.sort(
      (a, b) => Number(a.position) - Number(b.position)
    );

    return (
      <List>
        {advertisements.map((elem, index) => (
          <AdvertisementItem
            key={index}
            icon={elem.icon}
            headerText={elem.headline_text}
            bodyText={elem.main_text}
            link={elem.primary_link}
            onClick={() => {
              campaignsAPI.post(
                "/app-impression",
                {
                  event_type: "web_app_ad_click",
                  campaign_ids: [elem.campaign],
                  reminder_id: Number(reminderId),
                },
                {
                  headers: { Authorization: `Bearer ${userToken}` },
                }
              );
            }}
          />
        ))}
      </List>
    );
  }

  return (
    <List>
      {inactive_advertisements?.map((elem, index) => (
        <AdvertisementItem
          key={index}
          icon={elem.icon}
          headerText={elem.headline_text}
          bodyText={elem.main_text}
          link={elem.primary_link}
          onClick={() => {}}
        />
      ))}
    </List>
  );
};
