import { createAsyncAction, createAction } from "typesafe-actions";

import { ReminderCategoryInterface } from "../../../shared/interfaces/reminderCategory.interface";
import { RemindersCost } from "../../../shared/interfaces/remindersCost.interface";
import { RemindersList } from "../../../shared/interfaces/remindersList.interface";

export const fetchRemindersCategoriesAsync = createAsyncAction(
  "FETCH_REMINDERS_CATEGORIES_REQUEST",
  "FETCH_REMINDERS_CATEGORIES_SUCCESS",
  "FETCH_REMINDERS_CATEGORIES_FAILTURE"
)<void, ReminderCategoryInterface[], string>();

export const fetchRemindersCostAsync = createAsyncAction(
  "FETCH_REMINDERS_COST_REQUEST",
  "FETCH_REMINDERS_COST_SUCCESS",
  "FETCH_REMINDERS_COST_FAILTURE"
)<void, RemindersCost, string>();

export const fetchRemindersListAsync = createAsyncAction(
  "FETCH_REMINDERS_REQUEST",
  "FETCH_REMINDERS_SUCCESS",
  "FETCH_REMINDERS_FAILTURE"
)<void, RemindersList, string>();

export const removeRemindersData = createAction("REMOVE_REMINDERS_DATA")<
  void
>();
