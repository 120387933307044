import React, { useState } from "react";
import FacebookLoginBtn from "react-facebook-login";
import { useHistory } from "react-router-dom";
import AppleSignin from 'react-apple-signin-auth';
import { useGoogleLogin } from '@react-oauth/google';

import { ButtonStyles } from "./socialButton.interfaces";
import {
  ButtonWrapper,
  ButtonBody,
  SocialIcon,
  ButtonText,
  SocialName,
  ButtonOwerflow,
  FacebookButtonOwerflow,
} from "./socialButton.styles";
import { SignUpErrorModal } from "../../modals/signUpError/signUpError.component";
import { CountrySelectModal } from "../../modals/countrySelect/countrySelect.component";
import { ROUTES } from "../../shared/constants/routes";

import authAPI from "../../services/authApi";

import "./facebook.css";

export const GoogleButton: React.FC<ButtonStyles> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showCountryForm, setShowCountryForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();

  const login = useGoogleLogin({
    onSuccess: response => {
      const setToken = async () => {
        try {
          const resp = await authAPI.post("/social/google-login", {
            access_token: response.access_token,
          });
          if (resp) {
            localStorage.setItem("token", resp.data.access);
            localStorage.setItem("refresh", resp.data.refresh);
  
            if (resp.data.user.country === null) {
              handleOpenCountrieModal();
            } else {
              history.push(ROUTES.remindersList);
            }
          }
        } catch (error) {
          let serverMessage = error.response.request.response;
          serverMessage = serverMessage.slice(
            serverMessage.indexOf("Sorry"),
            serverMessage.length - 4
          );
  
          setErrorMessage(serverMessage);
  
          if (serverMessage) {
            handleOpenModal();
          }
        }
      };
  
      setToken();
    },
  });

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenCountrieModal = () => {
    setShowCountryForm(true);
  };

  const handleCloseCoutnrieModal = () => {
    setShowCountryForm(false);
  };

  return (
    <>
      <FacebookButtonOwerflow
        onClick={props.setPrivacy}
        privacy={props.privacy}
      />
      <ButtonWrapper
        social={props.social}
        onClick={() => login()}
        disabled={!props.privacy}
      >
        <ButtonOwerflow
          onClick={props.setPrivacy}
          privacy={props.privacy}
        />
        <ButtonBody>
          <SocialIcon social={props.social} src={props.icon} />
          <ButtonText social={props.social}>
            Continue with <SocialName>{props.social}</SocialName>
          </ButtonText>
        </ButtonBody>
      </ButtonWrapper>
      <SignUpErrorModal
        handleCloseModal={handleCloseModal}
        handleOpenModal={handleOpenModal}
        bodyText={errorMessage}
        showModal={showModal}
      />
      <CountrySelectModal
        handleCloseModal={handleCloseCoutnrieModal}
        handleOpenModal={handleOpenCountrieModal}
        showModal={showCountryForm}
      />
    </>
  );
};

export const FacebookButton: React.FC<ButtonStyles> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showCountryForm, setShowCountryForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();

  const responseFacebook = (response: any) => {
    const setToken = async () => {
      try {
        const resp = await authAPI.post("/social/facebook-login", {
          access_token: response.accessToken,
        });

        if (resp) {
          localStorage.setItem("token", resp.data.access);
          localStorage.setItem("refresh", resp.data.refresh);

          if (resp.data.user.country === null) {
            handleOpenCountrieModal();
          } else {
            history.push(ROUTES.remindersList);
          }
        }
      } catch (error) {
        let serverMessage = error.response.request.response;
        serverMessage = serverMessage.slice(
          serverMessage.indexOf("Sorry"),
          serverMessage.length - 4
        );

        setErrorMessage(serverMessage);

        if (serverMessage) {
          handleOpenModal();
        }
      }
    };

    setToken();
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenCountrieModal = () => {
    setShowCountryForm(true);
  };

  const handleCloseCoutnrieModal = () => {
    setShowCountryForm(false);
  };

  return (
    <>
      <FacebookLoginBtn
        onClick={props.setPrivacy}
        isDisabled={!props.privacy}
        appId="567510846931593"
        callback={responseFacebook}
        fields="email,picture"
        cssClass="facebook-button"
        textButton="Continue with "
      />
      <SignUpErrorModal
        handleCloseModal={handleCloseModal}
        handleOpenModal={handleOpenModal}
        bodyText={errorMessage}
        showModal={showModal}
      />
      <CountrySelectModal
        handleCloseModal={handleCloseCoutnrieModal}
        handleOpenModal={handleOpenCountrieModal}
        showModal={showCountryForm}
      />
    </>
  );
};

export const AppleButton: React.FC<ButtonStyles> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showCountryForm, setShowCountryForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();

  const responseApple = (response: any) => {
    const setToken = async () => {
      try {
        const resp = await authAPI.post("/social/apple-login", {
          access_token: response.authorization.code,
          web_client: true,
        });

        if (resp) {
          localStorage.setItem("token", resp.data.access);
          localStorage.setItem("refresh", resp.data.refresh);

          if (resp.data.user.country === null) {
            handleOpenCountrieModal();
          } else {
            history.push(ROUTES.remindersList);
          }
        }
      } catch (error) {
        let serverMessage = error.response.request.response;
        serverMessage = serverMessage.slice(
          serverMessage.indexOf("Sorry"),
          serverMessage.length - 4
        );

        setErrorMessage(serverMessage);

        if (serverMessage) {
          handleOpenModal();
        }
      }
    };

    setToken();
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenCountrieModal = () => {
    setShowCountryForm(true);
  };

  const handleCloseCoutnrieModal = () => {
    setShowCountryForm(false);
  };

  return (
    <>
      <AppleSignin
        /** Auth options passed to AppleID.auth.init() */
        authOptions={{
          clientId: 'com.getreminded.signin',
          scope: 'email name',
          redirectURI: window.location.origin,
          state: 'state',
          nonce: 'nonce',
          /** Uses popup auth instead of redirection */
          usePopup: true,
        }} // REQUIRED
        /** General props */
        uiType="dark"
        /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
        onSuccess={(response: any) => responseApple(response)} // default = undefined
        /** Called upon signin error */
        onError={({ error }: any) => {
          if (error === 'popup_closed_by_user') {
            setErrorMessage('Popup closed by user');
          } else {
            setErrorMessage(error);
          }
          if (error) {
            handleOpenModal();
          }
        }}
        /** Skips loading the apple script if true */
        skipScript={true}
        /** Apple image props */
        // iconProp={{ style: { marginTop: '10px' } }}
        /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
        // render={(props: any) => <button {...props}>My Custom Button</button>}
      />
      <SignUpErrorModal
        handleCloseModal={handleCloseModal}
        handleOpenModal={handleOpenModal}
        bodyText={errorMessage}
        showModal={showModal}
      />
      <CountrySelectModal
        handleCloseModal={handleCloseCoutnrieModal}
        handleOpenModal={handleOpenCountrieModal}
        showModal={showCountryForm}
      />
    </>
  );
};

export const SocialButton: React.FC<ButtonStyles> = (props) => {
  if (props.social === "google") {
    return (
      <GoogleButton
        social={props.social}
        icon={props.icon}
        setPrivacy={props.setPrivacy}
        privacy={props.privacy}
      />
    );
  }

  if (props.social === "apple") {
    return (
      <AppleButton
        social={props.social}
        setPrivacy={props.setPrivacy}
        privacy={props.privacy}
      />
    );
  }

  return (
    <FacebookButton
      social={props.social}
      icon={props.icon}
      setPrivacy={props.setPrivacy}
      privacy={props.privacy}
    />
  );
};
