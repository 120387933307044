import React from "react";

import { ServerErrorProps } from "./serverError.interfaces";
import { PageWrapper, PageHeader } from "./serverError.styles";

export const ServerError: React.FC<ServerErrorProps> = (props) => {
  return (
    <PageWrapper>
      <PageHeader>Page doesn't respond</PageHeader>
      <PageHeader>Error {props.code}</PageHeader>
    </PageWrapper>
  );
};
