import {monthsArray} from '../../../shared/constants/months';

export const getReminderData = (data: string) => {
  const dataArray = data.split("-");
  const [year, month, day] = dataArray;

  return {
    year: year,
    month: monthsArray[+month - 1],
    day: day,
  };
};
