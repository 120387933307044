import React from "react";

import { ReminderTypesProps } from "./reminderTypes.interfaces";
import {
  ReminderTypesContainer,
  ReminderTypesHeader,
  TypesList,
  TypeContainer,
  TypeText,
  ArrowLeft,
  TypeLine,
} from "./reminderTypes.styles";
import moreArrowRight from "../../../../assets/svg/moreArrowRight.svg";

export const ReminderTypes: React.FC<ReminderTypesProps> = (props) => {
  const renderTypes = () => {
    return props.reminderCategory.types.map((type) => {
      return (
        <TypeContainer
          key={type.id}
          onClick={() => {
            props.onTypeClick(type.id);
          }}
        >
          <TypeText>{type.name}</TypeText>
          <ArrowLeft src={moreArrowRight} alt="arrow-right" />
          <TypeLine />
        </TypeContainer>
      );
    });
  };

  return (
    <ReminderTypesContainer>
      <ReminderTypesHeader>
        {props.reminderCategory.select_type_label}
      </ReminderTypesHeader>
      <TypesList>{renderTypes()}</TypesList>
    </ReminderTypesContainer>
  );
};
