import { RoutesInterface } from "../interfaces/routes.interface";

export const ROUTES: RoutesInterface = {
  verifyEmail: {
    path: "/activation-success/*",
    route: (token: string) => `/activation-success/${token}`,
  },
  verifyNewEmail: {
    path: "/verify-new-email/*",
    route: (token: string) => `/verify-new-email/${token}`,
  },
  passwordResetConfirm: {
    path: "/password-reset/reset/*",
    route: (token: string) => `/password-reset/reset/${token}`,
  },
  forgotPassword: "/forgot-password/",
  oldVerificationLink: "/old-verification-link/",
  signUp: "/signup/",
  newPasswordForm: "/new-password-form/",
  unsubscribe: "/unsubscribe/*",
  verifyEmailMessage: "/verify-email-message/",
  resetPasswordMessage: "/password-reset/sent/",
  logIn: "/",
  remindersList: "/dashboard/",
  newReminder: "/new-reminder/",
  editReminder: {
    path: "/reminders/:id",
    route: (id: string) => `/reminders/${id}`,
  },
  accountSettings: "/settings/",
  changePassword: "/settings/update-password/",
};
