import React from "react";

import { OptionListProps } from "../../reminderForm.interfaces";

export const OptionsList: React.FC<OptionListProps> = (props) => {
  const styles = {
    fontFamily: "Avenir Next",
    fontSize: "18px",
    lineHeight: "21px",
  };

  const list = props.optionsList.map((elem) => {
    if (elem.name === "Other" && props.getOtherId) {
      setTimeout(() => {
        props.getOtherId!(elem.id!);
      }, 100);
    }

    return (
      <option key={elem.id} value={elem.id} style={styles}>
        {elem.name}
      </option>
    );
  });

  return <>{list}</>;
};
