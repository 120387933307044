import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { EmailVerifiedSuccess } from "./screens/verifyEmail/verifyEmail.component";
import { ChangeEmailSuccess } from "./screens/verifyNewEmail/verifyNewEmail.component";
import { OldVerificationLink } from "./screens/oldVerificationLink/oldVerificationLink.component";
import { ForgotPassword } from "./screens/forgotPassword/forgotPassword.component";
import { PasswordReset } from "./screens/passwordReset/password-reset.component";
import { SignUp } from "./screens/signUp/signUp.component";
import { NewPasswordForm } from "./screens/newPasswordForm/newPasswordForm.component";
import { UnsubscribeSuccess } from "./screens/unsubscribe/unsubscribe.component";
import { VerifyEmailMessage } from "./screens/verifyEmailMessage/verifyEmailMessage.comonent";
import { ResetPasswordMessage } from "./screens/resetPasswordMessage/resetPasswordMessage.component";
import { RemindersList } from "./screens/remindersList/remindersList.component";
import { NewReminder } from "./screens/newReminder/newReminder.component";
import { LogIn } from "./screens/logIn/logIn.component";
import { EditReminder } from "./screens/editReminder/editReminder.component";
import { AccountSettings } from "./screens/accountSettings/accountSettings.component";
import { ChangePassword } from "./screens/changePassword/changePassword.component";
import { ROUTES } from "./shared/constants/routes";

import "./App.css";

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path={ROUTES.verifyEmail.path}
          exact
          component={EmailVerifiedSuccess}
        />
        <Route
          path={ROUTES.verifyNewEmail.path}
          exact
          component={ChangeEmailSuccess}
        />
        <Route
          path={ROUTES.passwordResetConfirm.path}
          exact
          component={PasswordReset}
        />
        <Route
          path={ROUTES.oldVerificationLink}
          exact
          component={OldVerificationLink}
        />
        <Route path={ROUTES.forgotPassword} exact component={ForgotPassword} />

        <Route path={ROUTES.signUp} exact component={SignUp} />

        <Route path={ROUTES.logIn} exact component={LogIn} />

        <Route
          path={ROUTES.newPasswordForm}
          exact
          component={NewPasswordForm}
        />
        <Route path={ROUTES.unsubscribe} exact component={UnsubscribeSuccess} />
        <Route
          path={ROUTES.verifyEmailMessage}
          exact
          component={VerifyEmailMessage}
        />
        <Route
          path={ROUTES.resetPasswordMessage}
          exact
          component={ResetPasswordMessage}
        />
        <Route path={ROUTES.remindersList} exact component={RemindersList} />
        <Route path={ROUTES.newReminder} exact component={NewReminder} />
        <Route path={ROUTES.editReminder.path} exact component={EditReminder} />
        <Route
          path={ROUTES.accountSettings}
          exact
          component={AccountSettings}
        />
        <Route path={ROUTES.changePassword} exact component={ChangePassword} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
