import { createReducer } from "typesafe-actions";

import { StoreData } from "./reducers.interface";
import { fetchCountriesAsync } from "./../actions/countries.actions";
import { Countries } from "../../../shared/interfaces/countries.interface";

export const initialState = {
  countries: [],
  errors: "",
};

export const countriesReducer = createReducer(initialState)
  .handleAction(fetchCountriesAsync.request, (state: StoreData) => ({
    ...state,
  }))
  .handleAction(
    fetchCountriesAsync.success,
    (state: StoreData, action: { payload: Countries[] }) => {
      return { ...state, countries: action.payload };
    }
  )
  .handleAction(
    fetchCountriesAsync.failure,
    (state: StoreData, action: { payload: string }) => ({
      ...state,
      errors: action.payload,
    })
  );
