import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { ReminderFormProps } from "./reminderForm.interfaces";
import {
  ReminderFormContainer,
  FormFieldContainer,
  FormLabel,
  FormField,
  ButtonsContainer,
  SubmitButton,
  CancelButton,
  CancelButtonText,
  SelectField,
  FieldsInRowContainer,
  ArrowDown,
  ErrorMessage,
  DeleteReminder,
  DeleteContainer,
} from "./reminderForm.styles";
import { OptionsList } from "./components/optionsList/optionsList.component";
import { DateOptionsList } from "./components/dateOptionsList/dateOptionsList.component";
import { days, years } from "../../shared/constants/date";
import { monthsArray } from "../../shared/constants/months";
import { getReminderData } from "./utils/getReminderData";
import { ROUTES } from "../../shared/constants/routes";
import { AskAgain } from "../../modals/askAgain/askAgain.component";
import API from "../../services/api";

export const ReminderForm: React.FC<ReminderFormProps> = (props) => {
  const [otherId, setOtherId] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);

  const { register, handleSubmit, errors, watch } = useForm();
  const history = useHistory();
  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);

  let reminderDateObject;

  if (props.reminderForEdit) {
    reminderDateObject = getReminderData(props.reminderForEdit.expiry_date);
  } else {
    reminderDateObject = null;
  }

  const onFormSubmit = handleSubmit((data) => {
    const reminderData = {
      cost: data.cost,
      day: data.day,
      description: data.description,
      month: data.month,
      payment: data.payment,
      provider: data.provider,
      type: data.type,
      year: data.year,
      other_provider: data.other_provider,
    };

    setIsSubmited(true);

    props.setReminder!(reminderData);
  });

  const getOtherId = (id: number) => {
    setOtherId(id);
  };

  const renderOtherProviderSelect = () => {
    if (otherId === +watch("provider")) {
      return (
        <FormFieldContainer>
          <FormField
            name="other_provider"
            placeholder="Please specify"
            maxLength={45}
            defaultValue={
              props.reminderForEdit ? props.reminderForEdit.other_provider : ""
            }
            ref={register({
              validate: (value) =>
                /^[A-Za-z0-9 ]+$/.test(value) && value.length !== 0,
            })}
          />
          {(errors.other_provider && watch("other_provider").length === 0 && (
            <ErrorMessage>Field required</ErrorMessage>
          )) ||
            (errors.other_provider && (
              <ErrorMessage>
                Only letters, numbers and spaces are available
              </ErrorMessage>
            ))}
        </FormFieldContainer>
      );
    }

    return <></>;
  };

  const onCancelButtonClick = () => {
    if (props.reminderForEdit) {
      history.push(ROUTES.remindersList);
    } else {
      props.onTypeClick!(0);
    }
  };

  const frequencyList = () => {
    if (watch("type") && props.reminder) {
      return props.reminder.types
        .filter((type) => +type.id === +watch("type"))[0]
        .cost_frequency.map((elem) => ({
          id: elem.id,
          name: elem.frequency,
        }));
    } else {
      return props.selectedType!.cost_frequency.map((elem) => ({
        id: elem.id,
        name: elem.frequency,
      }));
    }
  };

  const isRenderFrequency = () => {
    if (watch("type") && props.reminder) {
      const [type] = props.reminder.types.filter(
        (elem) => +elem.id === +watch("type")
      );

      return type.cost_frequency.length > 0;
    } else {
      return props.selectedType!.cost_frequency.length > 0;
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const onModalCancelButtonClick = () => {
    handleCloseModal();
  };

  const deleteReminder = async () => {
    const userToken = localStorage.getItem("token");

    try {
      const resp = await API.delete(`/reminders/${props.reminderForEdit?.id}`, {
        headers: { Authorization: `Bearer ${userToken}` },
        data: { id: props.reminderForEdit?.id },
      });

      if (resp) {
        handleCloseModal();

        history.push(ROUTES.remindersList);
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  const setFalseIsSubmited = () => {
    if (isSubmited && props.serverError) {
      setIsSubmited(false);
    }
  };

  return (
    <ReminderFormContainer
      onSubmit={onFormSubmit}
      edit={props.reminderForEdit ? true : false}
    >
      {setFalseIsSubmited()}
      <FormFieldContainer>
        <FormLabel>Type*</FormLabel>
        <SelectField
          name="type"
          ref={register}
          defaultValue={props.selectedType?.id}
          default
        >
          <OptionsList
            optionsList={
              props.reminder
                ? props.reminder.types
                : [
                    {
                      id: props.selectedType?.id,
                      name: props.selectedType?.name,
                    },
                  ]
            }
          />
        </SelectField>
        <ArrowDown />
      </FormFieldContainer>
      <FormFieldContainer>
        <FormLabel>Provider*</FormLabel>
        <SelectField
          name="provider"
          ref={register}
          defaultValue={(function () {
            if (props.reminderForEdit) {
              return props.reminderForEdit.provider
                ? props.reminderForEdit.provider.id
                : "";
            } else {
              return "";
            }
          })()}
          default
        >
          <OptionsList
            optionsList={props.reminder.providers.filter((provider) =>
              watch("type")
                ? +provider.type === +watch("type")
                : provider.type === props.selectedType?.id
            )}
            provider
            getOtherId={getOtherId}
          />
        </SelectField>
        <ArrowDown />
      </FormFieldContainer>
      {renderOtherProviderSelect()}

      <FormFieldContainer>
        <FormLabel>Description</FormLabel>
        <FormField
          name="description"
          placeholder="Description.."
          ref={register}
          maxLength={45}
          defaultValue={
            props.reminderForEdit ? props.reminderForEdit.description : ""
          }
        />
      </FormFieldContainer>

      {isRenderFrequency() && (
        <FieldsInRowContainer>
          <FormFieldContainer size="low" mb>
            <FormLabel validationError={errors.cost}>
              Cost ({props.currency})
            </FormLabel>
            <FormField
              name="cost"
              placeholder="0.00"
              defaultValue={
                props.reminderForEdit
                  ? `${Math.round(+props.reminderForEdit.cost)}`
                  : ""
              }
              validationError={errors.cost}
              maxLength={7}
              ref={register({
                validate: (value) =>
                  value.length === 0 ||
                  /^(?:[1-9]\d*|0)(?:\.\d+)?$/.test(value),
              })}
            />
            {errors.cost && <ErrorMessage>Numbers only</ErrorMessage>}
          </FormFieldContainer>
          <FormFieldContainer size="medium" mb>
            <FormLabel>Payment frequency</FormLabel>
            <SelectField
              name="payment"
              ref={register}
              fontSizeLower
              selectValue={watch("payment")}
              defaultValue={
                props.reminderForEdit
                  ? props.reminderForEdit.cost_frequency_id
                  : ""
              }
            >
              <OptionsList optionsList={frequencyList()} provider />
            </SelectField>
            <ArrowDown />
          </FormFieldContainer>
        </FieldsInRowContainer>
      )}

      <FieldsInRowContainer>
        <FormFieldContainer size="low">
          <FormLabel
            validationError={errors.day || errors.month || errors.year}
          >
            Expiry date
          </FormLabel>
          <SelectField
            name="day"
            ref={register({
              validate: (value) => value !== "DEFAULT",
            })}
            validationError={errors.day}
            selectValue={watch("day")}
            defaultValue={
              reminderDateObject
                ? reminderDateObject.day
                : `${
                    tomorrowDate.getDate() < 10
                      ? "0" + tomorrowDate.getDate()
                      : tomorrowDate.getDate()
                  }`
            }
            fontSizeLower
            onChange={props.onDateInputChange}
          >
            <DateOptionsList list={days} />
          </SelectField>
          {(errors.day || errors.month || errors.year) && (
            <ErrorMessage>field required</ErrorMessage>
          )}
          {props.serverError && (
            <ErrorMessage>{props.serverError}</ErrorMessage>
          )}
          <ArrowDown />
        </FormFieldContainer>

        <FormFieldContainer size="low">
          <FormLabel />
          <SelectField
            name="month"
            ref={register({
              validate: (value) => value !== "DEFAULT",
            })}
            validationError={errors.month}
            selectValue={watch("month")}
            defaultValue={
              reminderDateObject
                ? reminderDateObject.month
                : `${monthsArray[tomorrowDate.getMonth()]}`
            }
            fontSizeLower
            onChange={props.onDateInputChange}
          >
            <DateOptionsList list={monthsArray} />
          </SelectField>
          <ArrowDown />
        </FormFieldContainer>

        <FormFieldContainer size="low">
          <FormLabel />
          <SelectField
            name="year"
            ref={register({
              validate: (value) => value !== "DEFAULT",
            })}
            validationError={errors.year}
            selectValue={watch("year")}
            defaultValue={
              reminderDateObject
                ? reminderDateObject.year
                : `${tomorrowDate.getFullYear()}`
            }
            fontSizeLower
            onChange={props.onDateInputChange}
          >
            <DateOptionsList list={years} />
          </SelectField>
          <ArrowDown />
        </FormFieldContainer>
      </FieldsInRowContainer>

      <ButtonsContainer>
        <SubmitButton
          type="submit"
          value="Save reminder"
          disabled={props.reminderForEdit ? false : isSubmited}
        />
        <CancelButton onClick={onCancelButtonClick}>
          <CancelButtonText>Cancel</CancelButtonText>
        </CancelButton>
      </ButtonsContainer>

      {props.reminderForEdit && (
        <DeleteContainer>
          <DeleteReminder onClick={handleOpenModal}>
            Delete reminder
          </DeleteReminder>
        </DeleteContainer>
      )}

      <AskAgain
        handleCloseModal={handleCloseModal}
        handleOpenModal={handleOpenModal}
        showModal={showModal}
        headerText="Delete reminder?"
        bodyText="Do you really want to delete the reminder?
        This action can’t be undone."
        submitButtonText="Continue"
        cancelCallback={onModalCancelButtonClick}
        submitCallback={deleteReminder}
      />
    </ReminderFormContainer>
  );
};
