import createSagaMiddleware from "redux-saga";
import { all, fork } from "redux-saga/effects";
import {
  remindersCategoriesListSaga,
  remindersListSaga,
  remindersCostSaga,
} from "./remindersList/sagas/remindersList.saga";
import { userDataSaga } from "./userData/sagas/userData.saga";
import { reminderDataSaga } from './reminderData/sagas/reminderData.saga';
import { countriesSaga } from './countries/sagas/countries.saga';

export const saga = createSagaMiddleware();

export function* rootSaga() {
  yield all([
    fork(remindersCategoriesListSaga),
    fork(remindersCostSaga),
    fork(remindersListSaga),
    fork(userDataSaga),
    fork(reminderDataSaga),
    fork(countriesSaga),
  ]);
}
