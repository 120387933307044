import styled from "styled-components";

import { StylesProps } from "./reminder.interfaces";

export const ReminderContainer = styled.div`
  height: 105px;
  width: 32%;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 19px;
  position: relative;

  &:nth-of-type(3n + 2) {
    margin-left: 2%;
    margin-right: 2%;
  }

  &:hover {
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.38243);
  }

  @media (max-width: 1260px) {
    width: 48%;
    margin: 0 0 19px 0;

    &:nth-of-type(3n + 2) {
      margin: 0;
    }

    &:nth-of-type(odd) {
      margin-right: 4%;
    }
  }
`;

export const ReminderIcon = styled.img`
  height: 48px;
  width: 48px;
  margin-left: 17px;
`;

export const ReminderIconDummy = styled.div`
  height: 48px;
  width: 48px;
  margin-left: 17px;
`;

export const ReminderDetails = styled.div`
  margin-left: 17px;
  display: flex;
  flex-direction: column;
  width: 70%;

  @media (max-width: 1550px) {
    width: 65%;
  }

  @media (max-width: 1320px) {
    width: 60%;
  }

  @media (max-width: 1260px) {
    width: 70%;
  }

  @media (max-width: 1070px) {
    width: 60%;
  }
`;

export const TypeTitle = styled.p`
  height: 18px;
  color: #787878;
  font-family: "Avenir Next";
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
`;

export const ReminderType = styled.p`
  height: 25px;
  color: #3c3c3c;
  font-family: "Avenir Next";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ReminderPrice = styled.p`
  height: 18px;
  opacity: 0.5;
  color: #000000;
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.31px;
  line-height: 18px;
`;

export const TimeContainer = styled.div`
  dusplay: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-top: 30px;
  position: absolute;
  right: 17px;
`;

export const TimeValue = styled.p`
  width: 26px;
  height: 20px;
  font-family: Oswald;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  position: absolute;
  top: 1px;
  right: 3px;
`;

export const TimeTitle = styled.p`
  height: 8px;
  color: ${(props: StylesProps) => (+props.time <= 10 ? "#d9006c" : "#FEC776")};
  font-family: "Avenir Next";
  font-size: 9.6px;
  font-weight: 600;
  letter-spacing: 0.36px;
  line-height: 14px;
  width: 100%;
  text-align: right;
  padding-right: 3px;
  box-sizing: border-box;
`;

export const ReminderTimeIcon = styled.img`
  width: 32px;
  height: 32px;
`;

export const ActLater = styled.p`
  color: #787878;
  width: 45px;
  font-family: "Avenir Next";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
  text-align: right;
  text-transform: uppercase;
  position: absolute;
  right: 17px;
`;
