import React from "react";
import { useForm } from "react-hook-form";

import { PasswordFormInterface } from "./newPasswordForm.interfaces";
import { FormProps } from "./newPasswordForm.interfaces";
import { SubmitButton } from "../../components/submitButton/submitButton.component";

import {
  MainContent,
  MainContentWrapper,
  MainContentHeader,
  FormLabel,
  FormField,
  BackToLogin,
  ErrorMessage,
} from "./newPasswordForm.styles";

export const NewPasswordForm: React.FC<FormProps> = (props) => {
  const { register, handleSubmit, errors, watch } = useForm<
    PasswordFormInterface
  >();

  const passwordValidation = () => {
    const password = watch("password");

    if (password.length === 0) {
      return <ErrorMessage>Field required</ErrorMessage>;
    }

    if (password.includes(" ")) {
      return <ErrorMessage>Spaces are not allowed</ErrorMessage>;
    }
          
    if (password.length < 6 || password.length > 20) {
      return <ErrorMessage>Password must be 6 characters minimum</ErrorMessage>;
    }
  };

  const confirmPasswordValidation = () => {
    const confirmPassword = watch('confirm_password');

    if (confirmPassword.length === 0) {
      return <ErrorMessage>Field required</ErrorMessage>;
    }

    return <ErrorMessage>Your password doesn't match</ErrorMessage>;
  };

  
  return (
    <>
      <MainContent>
        <MainContentWrapper>
          <MainContentHeader>Change password</MainContentHeader>
          <form onSubmit={handleSubmit(props.onFormSubmit)}>
            <FormLabel validationError={errors.password}>New Password</FormLabel>
            <FormField
              type="password"
              placeholder="New password"
              name="password"
              maxLength={20}
              validationError={errors.password}
              ref={register({
                validate: (value) =>
                  value.length >= 6 && value.length <= 20 && !value.includes(" "),
              })}
            />
            {errors.password && passwordValidation()}

            <FormLabel validationError={errors.confirm_password}>Confirm Password</FormLabel>
            <FormField
              type="password"
              placeholder="Confirm password"
              name="confirm_password"
              maxLength={20}
              ref={register({
                validate: (value) => value === watch("password") && value.length !== 0,
              })}
              validationError={errors.confirm_password}
            />
            {errors.confirm_password && confirmPasswordValidation()}
            <SubmitButton text="Change password" />
          </form>
          <BackToLogin>Back to login</BackToLogin>
        </MainContentWrapper>
      </MainContent>
    </>
  );
};
