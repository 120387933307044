import React from "react";

import { OldVerificationLinkProps } from "./oldVerificationLink.interfaces";
import { Button } from "../../components/button/button.component";
import {
  MainContent,
  MainContentHeader,
  MainContentText,
} from "./oldVerificationLink.styles";

export const OldVerificationLink: React.FC<OldVerificationLinkProps> = (
  props
) => {
  return (
    <MainContent>
      <MainContentHeader>Oops, we're sorry</MainContentHeader>
      <MainContentText>
        It looks like the verification link you clicked is too old.
        {props.signUp && " Please sign up again"}
      </MainContentText>
      <Button text={props.buttonText} />
    </MainContent>
  );
};
