import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Header } from "../../components/header/header.component";
import {
  PageWrapper,
  StepButton,
  StepButtonText,
  PageHeader,
} from "./newReminder.styles";
import { RemindersList } from "./components/remindersList/remindersList.component";
import { RootState, ReminderData } from "./newReminder.interfaces";
import { fetchRemindersCategoriesAsync } from "../../store/remindersList/actions/remindersList.actions";
import { fetchUserDataAsync } from "../../store/userData/actions/userData.actions";
import { AddNewReminder } from "./components/addNewReminder/addNewReminder.components";
import { ReminderForm } from "../../components/reminderForm/reminderForm.component";
import { monthsArray } from "../../shared/constants/months";
import { ROUTES } from "../../shared/constants/routes";
import { refreshToken } from "../../shared/utils/refreshToken";
import { ServerError } from "../../components/serverError/serverError.components";
import API from "../../services/api";

export const NewReminder: React.FC = () => {
  const [step, setStep] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedTypeId, setSelectedTypeId] = useState(0);
  const [serverError, setServerError] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  const remindersCategories = useSelector(
    (state: RootState) => state.remindersList.remindersListStore
  );
  const userData = useSelector((state: RootState) => state.userData.userData);

  useEffect(() => {
    if (remindersCategories.length === 0) {
      dispatch(fetchRemindersCategoriesAsync.request());
    }

    if (!userData.country) {
      dispatch(fetchUserDataAsync.request());
    }
  }, [dispatch, remindersCategories.length, userData.country]);

  const userDataError = useSelector(
    (state: RootState) => state.userData.errors
  );

  const onReminderClick = (id: number) => {
    setSelectedCategory(id);
  };

  const onTypeClick = (type: number) => {
    setSelectedTypeId(type);
    setStep(step === 1 ? 2 : 1);
  };

  const onDateInputChange = () => {
    if (serverError) {
      setServerError("");
    }
  };

  const setReminder = (data: ReminderData) => {
    const userToken = localStorage.getItem("token");
    const monthNumber = monthsArray.indexOf(data.month) + 1;
    const date = `${data.year}-${
      monthNumber < 10 ? "0" + monthNumber : monthNumber
    }-${data.day}`;

    let reminderData: {};

    if (data.payment !== "DEFAULT") {
      const frequency = remindersCategories
        .filter((elem) => elem.id === selectedCategory)[0]
        .types.filter((elem) => elem.id === +data.type)[0]
        .cost_frequency.filter((elem) => elem.id === +data.payment)[0];
      
      let annualCost: string;

      if (frequency) {
        if (frequency.frequency === "Monthly") {
          annualCost = `${+data.cost * 12}`;
        } else if (frequency.frequency === "Weekly") {
          annualCost = `${+data.cost * 12 * 4}`;
        } else if (frequency.frequency === "Quarterly") {
          annualCost = `${+data.cost * 4}`;
        } else {
          annualCost = data.cost;
        }
      } else {
        annualCost = "0";
      }

      reminderData = {
        category_id: selectedCategory,
        type_id: data.type,
        provider_id: data.provider,
        other_provider: data.other_provider,
        expiry_date: date,
        description: data.description,
        cost: data.cost || "0.00",
        annual_cost: annualCost,
        cost_frequency: frequency,
        cost_frequency_id: frequency ? frequency.id : null,
      };
    } else {
      reminderData = {
        category_id: selectedCategory,
        type_id: data.type,
        provider_id: data.provider,
        other_provider: data.other_provider,
        expiry_date: date,
        description: data.description,
        cost: data.cost || "0.00",
        annual_cost: "0.00",
      };
    }

    const setReminderData = async () => {
      try {
        const resp = await API.post("/reminders/", reminderData, {
          headers: { Authorization: `Bearer ${userToken}` },
        });

        if (resp) {
          history.push(ROUTES.remindersList);
        }
      } catch (error) {
        let serverErrorMessage = error.response.data.detail.expiry_date[0];

        if (serverErrorMessage.startsWith("Date")) {
          serverErrorMessage = serverErrorMessage.slice(
            0,
            serverErrorMessage.indexOf(".") + 1
          );
        }

        setServerError(serverErrorMessage);
      }
    };

    setReminderData();
  };

  const renderContent = () => {
    const [selectedReminder] = remindersCategories.filter(
      (category) => category.id === selectedCategory
    );

    let selectedType;

    if (selectedReminder) {
      selectedType = selectedReminder.types.filter(
        (type) => type.id === selectedTypeId
      )[0];
    }

    if (step === 1) {
      return (
        <>
          <PageHeader>New reminder</PageHeader>
          <RemindersList
            reminders={remindersCategories}
            onReminderClick={onReminderClick}
            onTypeClick={onTypeClick}
          />
        </>
      );
    }

    return (
      <>
        <AddNewReminder reminderCategory={selectedReminder} />
        <ReminderForm
          reminder={selectedReminder}
          selectedType={selectedType}
          onTypeClick={onTypeClick}
          setReminder={setReminder}
          serverError={serverError}
          onDateInputChange={onDateInputChange}
          currency={userData.country.currency}
        />
      </>
    );
  };

  const checkData = () => {
    if (userDataError) {
      const refresh = refreshToken();

      if (refresh) {
        history.push(ROUTES.logIn);
      }
    }
  };

  const renderPage = () => {
    if (+userDataError >= 500 && +userDataError === 404) {
      return <ServerError code={userDataError} />;
    }

    return (
      <>
        {checkData()}
        <Header isSignedIn />
        <PageWrapper>
          <StepButton activeStep={step === 1}>
            <StepButtonText activeStep={step === 1}>step 1</StepButtonText>
          </StepButton>
          <StepButton activeStep={step === 2}>
            <StepButtonText activeStep={step === 2}>step 2</StepButtonText>
          </StepButton>
          {renderContent()}
        </PageWrapper>
      </>
    );
  };

  return renderPage();
};
