import { combineReducers } from "redux";

import { remindersListReducer } from "./remindersList/reducers/remindersList.reducer";
import { userDataReducer } from "./userData/reducers/userData.reducer";
import { reminderDataReducer } from './reminderData/reducers/reminderData.reducer';
import { countriesReducer } from './countries/reducers/countries.reducer';

export const rootReducer = combineReducers({
  remindersList: remindersListReducer,
  userData: userDataReducer,
  reminderData: reminderDataReducer,
  countries: countriesReducer,
});
