import styled from "styled-components";

import skyline from "../../assets/img/skyline2.png";

export const FooterContainer = styled.footer`
  max-width: 1343px;
  height: 328px;
  position: absolute;
  width: 100%;
  margin: auto;
  left: 0;
  bottom: 0;
  right: 0;
  margin-bottom: 24px;
`;

export const Skyline2Container = styled.div`
  background: url(${skyline});
  background-position: bottom;
  background-repeat: no-repeat;
  height: 195px;
  width: 1109px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 auto;
`;

export const Skyline2Shadow = styled.div`
  height: 100px;
  background: radial-gradient(
    50% 40% at bottom,
    rgba(220, 220, 220, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
`;

export const Line = styled.div`
  height: 1px;
  width: 1342px;
  background: radial-gradient(circle, #c8c8c8 0%, rgba(255, 255, 255, 0) 100%);
`;

export const FooterNav = styled.nav`
  margin: 22px 0 38px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1380px) {
    padding: 0 50px;
  }
`;

export const Navigation = styled.ul`
  list-style-type: none;
  display: flex;
`;

export const NavigationItem = styled.li`
  height: 22px;
  color: #002758;
  font-family: "Avenir Next";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  margin-right: 56px;
  text-decoration: none;
`;

export const Social = styled.div`
  display: flex;
`;

export const SocialItem = styled.img`
  margin-left: 37px;
  cursor: pointer;
`;

export const CopyRight = styled.p`
  height: 22px;
  color: #a0a0a0;
  font-family: "Avenir Next";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-top: 28px;
`;

export const ImageContainer = styled.a``;
