import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { store } from './store/store';
import './index.css';
import App from './App';

ReactDOM.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId="110165321962-10j9dqjrvv28f21uq38gbat2vf0r74c2.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </Provider>,
  document.getElementById('root')
);
