import React from "react";

import { SignUpProps } from "./formsValidation.interfaces";
import { ValidationMessage } from "./formsValidation.styles";

export const SignUpNameError: React.FC<SignUpProps> = (props) => {
  const { val: first_name } = props;

  const renderMessage = () => {
    if (first_name!.length === 0) {
      return <ValidationMessage>Field required</ValidationMessage>;
    }

    return (
      <ValidationMessage>
        First name must be 2 characters minimum
      </ValidationMessage>
    );
  };

  return renderMessage();
};

export const SignUpEmailError: React.FC<SignUpProps> = (props) => {
  const { val: email } = props;

  const renderMessage = () => {
    if (email!.length === 0) {
      return <ValidationMessage>Field required</ValidationMessage>;
    }

    if (email === 'false') {
      return <ValidationMessage>This email is already in use</ValidationMessage>;
    }

    return <ValidationMessage>Mailbox format error</ValidationMessage>;
  };

  return renderMessage();
};

export const SignUpCountryError: React.FC = () => {
  return <ValidationMessage>Field required</ValidationMessage>;
};

export const SignUpPosctodeError: React.FC<SignUpProps> = (props) => {
  const { val: postcode } = props;

  if (postcode!.length < 4) {
    return (
      <ValidationMessage>
        Postcode / Zip code must be 4 characters minimum
      </ValidationMessage>
    );
  }

  return <ValidationMessage>Postcode / Zip code format error</ValidationMessage>;
};

export const SignUpPasswordValidation: React.FC<SignUpProps> = (props) => {
  const { val: password } = props;

  if (password!.length === 0) {
    return <ValidationMessage>Field required</ValidationMessage>;
  }

  if (password!.includes(" ")) {
    return <ValidationMessage>Spaces are not allowed</ValidationMessage>;
  }

  return (
    <ValidationMessage>Password must be 6 characters minimum</ValidationMessage>
  );
};

export const SignUpConfirmPasswordValidation: React.FC<SignUpProps> = (
  props
) => {
  const { val: confirm_password } = props;

  if (confirm_password!.length === 0) {
    return <ValidationMessage>Field required</ValidationMessage>;
  }

  return <ValidationMessage>Your password doesn't match</ValidationMessage>;
};
