import styled from "styled-components";
import { Link } from "react-router-dom";

export const PageHeader = styled.div`
  width: 100%;
  max-width: 1440px;
  height: 99px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
`;

export const HeaderLine = styled.div`
  box-sizing: border-box;
  height: 2px;
  width: 100%;
  max-width: 1440px;
  border: 1px solid #f0f0f0;
  align-self: flex-end;
`;

export const PageHeaderGreen = styled.div`
  width: 100%;
  background-color: #00d6c2;
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderNavigation = styled.nav`
  height: 100%;
  display: flex;
`;

export const NavigationLinkContainer = styled.div`
  height: 100%;
  padding: 0 33px;
  border-left: 2px solid rgba(0, 41, 91, 0.340472);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const NavigationLink = styled(Link)`
  color: #012c68;
  font-family: "Avenir Next";
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 0;
`;

export const NavigationOutsideLink = styled.a`
  color: #012c68;
  font-family: "Avenir Next";
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 0;
`;
