import { createAsyncAction, createAction } from "typesafe-actions";

import { ReminderInterface } from "./../../../shared/interfaces/reminder.interface";

export const fetchReminderDataAsync = createAsyncAction(
  "FETCH_REMINDER_DATA_REQUEST",
  "FETCH_REMINDER_DATA_SUCCESS",
  "FETCH_REMINDER_DATA_FAILTURE"
)<string, ReminderInterface, string>();

export const removeReminderData = createAction("REMOVE_REMINDER_DATA")<void>();
