import styled from "styled-components";
import { Link } from "react-router-dom";

import { StylesProps } from './unsubscribe.interfaces';

export const UnsubscribeWrapper = styled.div`
  padding-bottom: 400px;
`;

export const UnsubscribeContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  background-color: #d7f6ee;
  color: #254c5d;
  border-radius: 10px;
  padding: 20px;
  font-family: "Avenir Next";
  margin: 0 auto;
  margin-top: 50px;
`;

export const UnsubscribeHeader = styled.p`
  font-size: 22px;
  margin-bottom: 20px;
  margin-top: 15px;
  fonrt-weight: bolder;
  line-height: 1.1;
`;

export const UnsubscribeBody = styled.p`
  font-size: 16px;
  font-weight: bolder;
  margin-bottom: 20px;
  color: ${(props: StylesProps) => props.errorMessage ? "#E51450" : "#000000"};
`;

export const ReturnLink = styled.a`
  color: #0dc799;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
`;

export const ReturnLinl = styled(Link)`
  color: #0dc799;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
`;
