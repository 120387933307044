import React from "react";

import {
  MessageContainer,
  MessageWrapper,
  MessageHeaderContainer,
  MessageHeader,
  Cross,
  MessageBody,
  MessageLink,
  Arrow,
} from "./customerSurveyMessage.styles";
import { CustomerSurveyMessageProps } from "./customSurveyMessage.interfaces";
import takeSurvyArrow from "../../assets/svg/takeSurveyArrow.svg";
import takeSurveyCross from "../../assets/svg/takeSurveyCross.svg";

export const CustomerSurveyMessage: React.FC<CustomerSurveyMessageProps> = (
  props
) => {
  return (
    <MessageContainer>
      <MessageWrapper>
        <MessageHeaderContainer>
          <MessageHeader>{props.survey.title}</MessageHeader>
          <Cross src={takeSurveyCross} onClick={props.onCrossClick} />
        </MessageHeaderContainer>
        <MessageBody>{props.survey.main_text}</MessageBody>
        <MessageLink
          href={props.survey.link}
          onClick={props.onCrossClick}
          target="blank"
        >
          {props.survey.link_label}
          <Arrow src={takeSurvyArrow} />
        </MessageLink>
      </MessageWrapper>
    </MessageContainer>
  );
};
