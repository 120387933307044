import React from "react";

import { AddNewReminderProps } from "./addNewReminder.interfaces";
import {
  PageHeaderContainer,
  ReminderIconContainer,
  ReminderIcon,
  ReminderName,
  Line,
} from "./addNewReminder.styles";

export const AddNewReminder: React.FC<AddNewReminderProps> = (props) => {
  return (
    <>
      <PageHeaderContainer>
        <ReminderIconContainer>
          <ReminderIcon
            src={props.reminderCategory.coloured_icon}
            alt="reminder-icon"
          />
        </ReminderIconContainer>
        <ReminderName>{props.reminderCategory.name}</ReminderName>
      </PageHeaderContainer>
      <Line />
    </>
  );
};
