import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  SettingsForm,
  FormFieldContainer,
  FormLabel,
  FormField,
  SelectField,
  ButtonsContainer,
  SubmitButton,
  CancelButton,
  CancelButtonText,
  ArrowDown,
  LinksContainer,
  ChangePasswordLink,
  DeleteAccount,
  ErrorMessage,
} from "./accountSettingsForm.styles";
import {
  AccountSettingsFormProps,
  FormDataInterface,
} from "./accountSettingsForm.interface";
import { CountriesList } from "../countriesList/countriesList.component";
import {
  SignUpNameError,
  SignUpEmailError,
  SignUpPosctodeError,
} from "../formsValidation/formsValidation.component";
import { AskAgain } from "../../modals/askAgain/askAgain.component";
import { ROUTES } from "../../shared/constants/routes";
import { removeRemindersData } from "../../store/remindersList/actions/remindersList.actions";
import usersAPI from "../../services/usersApi";
import authAPI from "../../services/authApi";


export const AccountSettingsForm: React.FC<AccountSettingsFormProps> = (
  props
) => {
  const [postcodeValue, setPostcodeValue] = useState(props.userData.postcode);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [newEmailError, setNewEmailError] = useState('');
  const { register, handleSubmit, watch, errors, control } = useForm();

  const history = useHistory();
  const dispatch = useDispatch();

  const onFormSubmit = handleSubmit((data) => {
    setFormData(data);

    if (+data.country !== +props.userData.country.id) {
      handleOpenModal();
    } else {
      moveDataToServer(data);
    }
  });

  const onCancelButtonClick = () => {
    handleCloseModal();
  };

  const onCancelDeleteModalButtonClick = () => {
    handleCloseDeleteModal();
  };

  const logOut = async () => {
    const refreshToken = localStorage.getItem("refresh");
    const accessToken = localStorage.getItem("token");

    try {
      const resp = await authAPI.post(
        "/logout",
        { refresh: refreshToken },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (resp) {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");

        dispatch(removeRemindersData());
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  const changeEmail = async (email: string) => {
    const userToken = localStorage.getItem("token");

    try {
      const resp = await authAPI.post("/change-email", { new_email: email }, {
        headers: { Authorization: `Bearer ${userToken}` },
      });

      if (resp) {
        logOut();

        history.push(ROUTES.verifyEmailMessage);
      }
    } catch (error) {
      setNewEmailError(error.response.data.detail.new_email[0]);
    }
  }
  
  const moveDataToServer = async (data: FormDataInterface) => {
    const userToken = localStorage.getItem("token");

    const dataToServer = {
      first_name: data.first_name,
      postcode: data.postcode || "",
      email: data.email,
      country_id: +data.country!,
    };

    try {
      const resp = await usersAPI.patch("/me/profile/edit", dataToServer, {
        headers: { Authorization: `Bearer ${userToken}` },
      });

      if (resp) {
        handleCloseModal();

        if (data.email !== props.userData.email) {
          changeEmail(data.email!);
        } else {
          setTimeout(() => {
            history.push(ROUTES.remindersList);
          }, 300);
        }
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  const deleteAccount = async () => {
    const userToken = localStorage.getItem("token");

    try {
      const resp = await usersAPI.delete("/me/delete-account", {
        headers: { Authorization: `Bearer ${userToken}` },
      });

      if (resp) {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");

        history.push(ROUTES.logIn);
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  const onPostcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const postcode = event.target.value;

    if (/^[A-Za-z0-9 ]+$/.test(postcode) || !postcode) {
      setPostcodeValue(postcode);
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleOpenDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const omEmailChange = () => {
    if (newEmailError) {
      setNewEmailError("");
    }
  }

  return (
    <SettingsForm onSubmit={onFormSubmit}>
      <FormFieldContainer>
        <FormLabel validationError={errors.first_name}>First Name</FormLabel>
        <FormField
          name="first_name"
          placeholder="Name"
          maxLength={20}
          defaultValue={props.userData.first_name}
          validationError={errors.first_name}
          ref={register({
            validate: (value) => value.length >= 2,
          })}
        />
        {errors.first_name && <SignUpNameError val={watch("first_name")} />}
      </FormFieldContainer>

      <FormFieldContainer>
        <FormLabel validationError={errors.email || newEmailError}>Email</FormLabel>
        <FormField
          name="email"
          placeholder="Email"
          maxLength={64}
          disabled={props.userData.third_type !== "email"}
          defaultValue={props.userData.email}
          validationError={errors.email || newEmailError}
          onChange={omEmailChange}
          ref={register({
            validate: (value) =>
              value.includes("@") &&
              value.includes(".") &&
              value.length > 0 &&
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                value
              ),
          })}
        />
        {newEmailError && <ErrorMessage>{newEmailError}</ErrorMessage>}
        {errors.email && <SignUpEmailError val={watch("email")} />}
      </FormFieldContainer>

      <FormFieldContainer>
        <FormLabel validationError={errors.postcode}>Postcode / Zip code</FormLabel>
        <Controller
          as={
            <FormField
              placeholder="Postcode / Zip code"
              maxLength={16}
              defaultValue={props.userData.postcode}
              validationError={errors.postcode}
              value={postcodeValue || ""}
              onChange={onPostcodeChange}
            />
          }
          name="postcode"
          control={control}
          rules={{
            validate: (value) =>
              !value || (value.length >= 4 && /^[A-Za-z0-9 ]+$/.test(value)),
          }}
        />
        {errors.postcode && <SignUpPosctodeError val={watch("postcode")} />}
      </FormFieldContainer>

      <FormFieldContainer>
        <FormLabel>Country</FormLabel>
        <SelectField
          name="country"
          defaultValue={props.userData.country.id}
          ref={register}
        >
          <CountriesList countries={props.countries} currency />
        </SelectField>
        <ArrowDown />
      </FormFieldContainer>

      <ButtonsContainer>
        <SubmitButton type="submit" value="Save changes" />
        <CancelButton onClick={props.onCancelButtonClick}>
          <CancelButtonText>Cancel</CancelButtonText>
        </CancelButton>
      </ButtonsContainer>

      <AskAgain
        handleCloseModal={handleCloseModal}
        handleOpenModal={handleOpenModal}
        showModal={showModal}
        headerText="Change country"
        bodyText="Changing country will delete all your current reminders. Do you want to continue?"
        submitButtonText="Continue"
        cancelCallback={onCancelButtonClick}
        submitCallback={() => {
          moveDataToServer(formData);
        }}
      />

      <AskAgain
        handleCloseModal={handleCloseDeleteModal}
        handleOpenModal={handleOpenDeleteModal}
        showModal={showDeleteModal}
        headerText="Delete account"
        bodyText="Do you really want to delete the account?
        This action can’t be undone."
        submitButtonText="Delete account"
        cancelCallback={onCancelDeleteModalButtonClick}
        submitCallback={deleteAccount}
      />

      <LinksContainer byEmail={props.userData.third_type === "email"}>
        {props.userData.third_type === "email" && (
          <ChangePasswordLink to={ROUTES.changePassword}>
            Change password
          </ChangePasswordLink>
        )}
        <DeleteAccount onClick={handleOpenDeleteModal}>
          Delete Account
        </DeleteAccount>
      </LinksContainer>
    </SettingsForm>
  );
};
