import styled from "styled-components";
import { StylesProps } from "./remindersList.interfaces";

export const PageWrapper = styled.div`
  width: 100%;
  padding: ${(props: StylesProps) => props.survey ? "38px 74px 52px" : "84px 74px 52px"};
  box-sizing: border-box;
  background-color: #f2f2f2;
  min-height: 100vh;
`;

export const PageHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const PageHeaderText = styled.p`
  height: 48px;
  width: 225px;
  color: #00295b;
  font-family: Avenir;
  font-size: 45px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 48px;
`;

export const PageHeaderButton = styled.button`
  width: ${(props: StylesProps) => (props.green ? "164px" : "149px")};
  height: 46px;
  background: ${(props: StylesProps) => (props.green ? "#00D6C2" : "#00295b")};
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const ButtonText = styled.span`
  width: 135px;
  height: 22px;
  font-family: "Avenir Next";
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${(props: StylesProps) => (props.green ? "#031E52" : "#00f4cf")};
  font-weight: bold;
`;

export const RemindersCostContainer = styled.p`
  height: 25px;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Avenir Next";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin: 15px 0 42px;
`;

export const RemindersConstPrice = styled.span`
  display: inline-block;
  margin-left: 15px;
  font-weight: bold;
`;

export const HorisontalLine = styled.div`
  height: 2px;
  border: 1px solid #d2d2d2;
  margin: 27px 0 28px;
  box-sizing: border-box;
`;

export const NoRemindersContainer = styled.div`
  min-height: 654px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NoRemindersIcon = styled.img`
  height: 96px;
  width: 96px;
  margin-top: 84px;
`;

export const NoRemindersText = styled.p`
  height: 25px;
  width: 334px;
  color: #787878;
  font-family: "Avenir Next";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  margin: ${(props: StylesProps) =>
    props.green ? "84px 0 35px" : "10px 0 35px"};
`;

export const RemindersWrapper = styled.div``;

export const RemindersContainer = styled.div`
  // min-height: 300px;
  display: flex;
  flex-wrap: wrap;
  // margin-bottom: ${(props: StylesProps) => props.first ? "0" : "328px"};
`;

export const ReminderAct = styled.p`
  height: 22px;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Avenir Next";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  margin: 26px 0 19px;
`;
