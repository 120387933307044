import styled from "styled-components";

import { StylesProps } from './newPasswordForm.interfaces';

export const MainContent = styled.div`
  height: 830px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
`;

export const MainContentWrapper = styled.div`
  width: 370px;
  margin-top: 129px;
  display: flex;
  flex-direction: column;
  margin-bottom: 328px;
`;

export const MainContentHeader = styled.h2`
  height: 36px;
  width: 343px;
  color: #00295b;
  font-family: "Avenir Next";
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 36px;
  margin-bottom: 24px;
`;

export const MainContentText = styled.p`
  height: 44px;
  width: 342px;
  color: #00295b;
  font-family: "Avenir Next";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 21px;
`;

export const FormLabel = styled.label`
  display: block;
  color: ${(props: StylesProps) => props.validationError ? "#E51450" : "#444444;"};
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  margin: 0 0 5px 8px;
`;

export const FormField = styled.input`
  box-sizing: border-box;
  width: 370px;
  border: ${(props: StylesProps) => props.validationError ? "1px solid #E51450" : "1px solid #b4b3b3"};
  border-radius: 8px;
  background-color: #ffffff;
  color: #444440;
  font-family: "Avenir Next";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  padding: 14px 0 12px 16px;
  margin-bottom: 47px;
  outline: none;

  &:focus {
    border-color: ${(props: StylesProps) => props.validationError ? "#E51450" : "#00d6c2"};
  }
`;

export const BackToLogin = styled.div`
  height: 26px;
  width: 238px;
  color: #00295b;
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  margin-top: 37px;
  align-self: center;
  cursor: pointer;
`;

export const ErrorMessage = styled.p`
  height: 15px;
  color: #e51450;
  font-family: "Avenir Next";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.33px;
  line-height: 15px;
  margin: -42px 0 47px 4px;
`;
