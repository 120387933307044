import styled from "styled-components";
import { Link } from 'react-router-dom';

import { StylesProps } from "./signUp.interfaces";

export const SignUpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SignUpContainer = styled.div`
  width: 370px;
  display: flex;
  flex-direction: column;
  margin-bottom: 328px;
`;

export const SignUpHeader = styled.h2`
  height: 36px;
  width: 343px;
  color: #00295b;
  font-family: "Avenir Next";
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
  margin: 119px 0 12px;
`;

export const SignUpText = styled.p`
  height: 50px;
  width: 343px;
  color: #00295b;
  font-family: "Avenir Next";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 10px;
`;

export const SignUpForm = styled.form`
  span {
    opacity: 1 !important;
    transition: none;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 35px;
`;

export const SignUpCheckbox = styled.input`
  height: 14px;
  width: 14px;
  margin: 5px 16px 5px 5px;
  outline: none;
  border: none;
`;

export const CheckboxLabel = styled.label`
  height: 32px;
  width: 322px;
  opacity: 0.69;
  color: #000000;
  font-family: "Avenir Next";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
`;

export const LineContainer = styled.div`
  height: 19px;
  width: 354px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 36px;
`;

export const Line = styled.div`
  box-sizing: border-box;
  height: 2px;
  width: 144.42px;
  border: 1px solid #000000;
  opacity: 0.5;
`;

export const LineContainerText = styled.p`
  height: 19px;
  width: 23px;
  opacity: 0.5;
  color: #00295b;
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.76px;
  line-height: 19px;
`;

export const FormFieldContainer = styled.div`
  width: 370px;
  height: 72px;
  position: ${(props: StylesProps) => (props.rel ? "relative" : "static")};
  margin-bottom: ${(props: StylesProps) =>
    props.validationError ? "30px" : "25px"};

  &:last-of-type {
    margin-bottom: 32px;
  }
`;

export const FormLabel = styled.label`
  display: block;
  height: 19px;
  color: ${(props: StylesProps) =>
    props.validationError ? "#E51450" : "#444444"};
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  margin: 0 0 5px 8px;
`;

export const FormField = styled.input`
  width: 370px;
  height: 46px;
  border: ${(props: StylesProps) =>
    props.validationError ? "1px solid #E51450" : "1px solid #b4b3b3"};
  border-radius: 8px;
  background-color: #ffffff;
  color: #444440;
  font-family: "Avenir Next";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  padding: 0 0 0 16px;
  outline: none;
  box-sizing: border-box;
  padding: 14px 0 15px 16px;

  &:focus {
    border-color: ${(props: StylesProps) =>
      props.validationError ? "#E51450" : "#00d6c2"};
  }

  &::placeholder {
    color: #949594;
    font-family: "Avenir Next";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
  }
`;

export const FormSelectField = styled.select`
  width: 370px;
  height: 46px;
  border: ${(props: StylesProps) =>
    props.validationError ? "1px solid #E51450" : "1px solid #b4b3b3"};
  border-radius: 8px;
  background-color: #ffffff;
  color: ${(props: StylesProps) =>
    props.countryField && props.countryField !== 'DEFAULT' ? "#444440" : "#949594"};
  font-family: "Avenir Next";
  font-size: ${(props: StylesProps) =>
    props.countryField && props.countryField !== 'DEFAULT' ? "16px" : "14px"};
  letter-spacing: 0;
  line-height: 19px;
  padding: 0 20px 0 16px;
  outline: none;
  appearance: none;
`;

export const SelectArrow = styled.div`
  height: 0;
  width: 0;
  border-style: solid;
  border-width: 8px 6px 0 6px;
  border-color: #3c3c3c transparent transparent transparent;
  position: absolute;
  bottom: 21px;
  right: 21px;
`;

export const TermsContainer = styled.div`
  margin-bottom: 35px;
`;

export const Terms = styled.p`
  height: 26px;
  color: #00295b;
  font-family: "Avenir Next";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin: ${(props: StylesProps) => (props.firstTerm ? "32px 0 8px" : "0")};
`;

export const TermsLink = styled.a`
  font-weight: bold;
  text-decoration: none;
  color: #00295b;
`;

export const InsideLink = styled(Link)`
  font-weight: bold;
  text-decoration: none;
  color: #00295b;
`;

export const ErrorMesage = styled.p`
  margin: 0 0 12px 0;
  height: 15px;
  width: 357px;
  color: #e51450;
  font-family: "Avenir Next";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.33px;
  line-height: 15px;
`;
