import React from "react";

import { RemindersListProps } from "./remindersList.interfaces";
import { ReminderCategory } from "../reminderCategory/reminderCategory.component";
import { ListContainer } from "./remindersList.styles";

export const RemindersList: React.FC<RemindersListProps> = (props) => {
  const renderList = () => {
    return props.reminders.map((reminder) => {
      return (
        <ReminderCategory
          key={reminder.id}
          id={reminder.id}
          icon={reminder.coloured_icon}
          name={reminder.name}
          reminderCategory={reminder}
          onReminderClick={props.onReminderClick}
          onTypeClick={props.onTypeClick}
        />
      );
    });
  };

  return <ListContainer>{renderList()}</ListContainer>;
};
