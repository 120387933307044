import React from "react";
import { useHistory } from 'react-router-dom';

import { BaseButton, ButtonText } from "./button.styles";
import { ButtonProps } from "./button.interfaces";
import { ROUTES } from '../../shared/constants/routes';


export const Button: React.FC<ButtonProps> = (props) => {
  const history = useHistory();

  const onButtonClick = () => {
    if (props.handleCloseModal) {
      props.handleCloseModal();
    }

    if (props.text === 'Log in') {
      history.push(ROUTES.logIn);
    } else {
      history.push(ROUTES.signUp);
    }
  };
  
  return (
    <BaseButton reset={props.reset} onClick={onButtonClick}>
      <ButtonText reset={props.reset}>{props.text}</ButtonText>
    </BaseButton>
  );
};
