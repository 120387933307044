import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';

import { Header } from "../../components/header/header.component";
import { Footer } from "../../components/footer/footer.component";
import { PageWrapper } from "../../components/wrapper/wrapper.component";
import {
  MainContent,
  MainContentWrapper,
  MainContentHeader,
  MainContentText,
  FormFieldContainer,
  FormField,
  FormLabel,
  BackToLogin,
  SubmitButtom,
  ForgotPasswordForm,
  InvalidEmailError,
} from "./forgotPassword.styles";
import { ROUTES } from "../../shared/constants/routes";
import { SignUpEmailError } from "../../components/formsValidation/formsValidation.component";
import { SignUpErrorModal } from "../../modals/signUpError/signUpError.component";

import authAPI from "../../services/authApi";

export const ForgotPassword: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [serverError, setServerError] = useState("");

  const { register, handleSubmit, errors, watch } = useForm();

  const history = useHistory();

  const onFormSubmit = handleSubmit((data) => {
    const moveEmailToServer = async () => {
      try {
        const resp = await authAPI.post("/reset-password", data);

        if (resp) {
          history.push(ROUTES.resetPasswordMessage);
        }
      } catch (error) {
        let serverErrorMessage = error.response.request.response;
        serverErrorMessage = serverErrorMessage.slice(
          serverErrorMessage.indexOf("[") + 2,
          serverErrorMessage.length - 4
        );

        if (serverErrorMessage.startsWith('You')) {
          handleOpenModal();
        } 

        setServerError(serverErrorMessage);
      }
    };

    moveEmailToServer();
  });

  const onInputChange = () => {
    if (serverError) {
      setServerError("");
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Header />
      <PageWrapper>
        <MainContent>
          <MainContentWrapper>
            <MainContentHeader>Forgot Password</MainContentHeader>
            <MainContentText>
              Enter your email address and we will email a link to reset your
              password
            </MainContentText>
            <ForgotPasswordForm onSubmit={onFormSubmit}>
              <FormFieldContainer validationError={errors.email}>
                <FormLabel validationError={errors.email}>Email</FormLabel>
                <FormField
                  name="email"
                  placeholder="User@email.com"
                  maxLength={64}
                  onChange={onInputChange}
                  validationError={errors.email}
                  ref={register({
                    validate: (value) =>
                      value.length > 0 &&
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        value
                      ),
                  })}
                />
                {errors.email && <SignUpEmailError val={watch("email")} />}
                {serverError.startsWith('Sorry') && (
                  <InvalidEmailError>{serverError}</InvalidEmailError>
                )}
              </FormFieldContainer>

              <SubmitButtom
                type="submit"
                value="Reset password"
                serverError={serverError}
              />
              <SignUpErrorModal
                handleCloseModal={handleCloseModal}
                handleOpenModal={handleOpenModal}
                bodyText={serverError}
                showModal={showModal}
              />
            </ForgotPasswordForm>
            <BackToLogin to={ROUTES.logIn}>Back to login</BackToLogin>
          </MainContentWrapper>
        </MainContent>
      </PageWrapper>
      <Footer />
    </>
  );
};
