import styled from "styled-components";

export const PageHeaderContainer = styled.div`
  display: flex;
  margin-top: 13px;
  margin-bottom: 41px;
  align-items: center;
`;

export const ReminderIconContainer = styled.div`
  height: 60px;
  width: 64px;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ReminderIcon = styled.img`
  wiwth: 32px;
  height: 32px;
`;

export const ReminderName = styled.h1`
  height: 48px;
  color: #00295b;
  font-family: Avenir;
  font-size: 45px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 48px;
  margin-left: 17px;
`;

export const Line = styled.div`
  box-sizing: border-box;
  height: 2px;
  width: 100%;
  border: 1px solid #d2d2d2;
`;
