import styled from "styled-components";

export const ReminderTypesContainer = styled.div`
  margin-left: 24px;
  width: 475px;
  box-sizing: border-box;
`;

export const ReminderTypesHeader = styled.p`
  height: 22px;
  color: #4e4e4e;
  font-family: "Avenir Next";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  margin: 19px 0 14px;
`;

export const TypesList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TypeContainer = styled.div`
  height: 55px;
  width: 450px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: #3c3c3c;

  &:hover {
    color: #00d6c2;
  }
  
  &:last-of-type {
    margin-bottom: 22px;
  }
`;

export const TypeText = styled.span`
  height: 25px;
  font-family: "Avenir Next";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 25px;
  align-self: flex-start;
  margin-top: 14px;
`;

export const ArrowLeft = styled.img`
  height: 17px;
  width: 17px;
  position: absolute;
  right: 9px;
`;

export const TypeLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e6e6e6;
  align-self: flex-end;
`;
