import styled from 'styled-components';

import { ButtonStylesProps } from './button.interfaces';

export const BaseButton = styled.button`
  width: ${(props: ButtonStylesProps) => props.reset ? "100%" : "179px"};
  height: 44px;
  border-radius: 6px;
  background: linear-gradient(45deg, #00c8aa 0%, #00dac2 100%);
  border: none;
  cursor: pointer;
  outline: none;
`;


export const ButtonText = styled.span`
  display: inline-block;
  width: ${(props: ButtonStylesProps) => props.reset ? "323.75px" : "156.63px"};
  height: 27px;
  color: #ffffff;
  font-family: "Avenir Next";
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 27px;
  text-align: center;
`;