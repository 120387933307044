import styled from "styled-components";

export const PageWrapper = styled.div`
  background-color: #f2f2f2;
  padding: 0 74px;
  min-height: 100vh;
  padding-bottom: 35px;
  padding-top: 91px;
`;

export const PageHeader = styled.h1`
  height: 48px;
  color: #00295b;
  font-family: Avenir;
  font-size: 45px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 48px;
`;

export const Line = styled.div`
  box-sizing: border-box;
  height: 2px;
  width: 100%;
  border: 1px solid #d2d2d2;
  margin: 44.75px 0 35.25px;
`;
