import styled from "styled-components";

import { StylesProps } from "./reminderForm.interfaces";

export const ReminderFormContainer = styled.form`
  background: #ffffff;
  width: 476px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  padding: ${(props: StylesProps) =>
    props.edit ? "33px 33px 0" : "33px 33px 60px"};
  box-sizing: border-box;
  margin-top: 35.25px;
  margin-bottom: 64px;
`;

export const FormFieldContainer = styled.div`
  margin-bottom: ${(props: StylesProps) => (props.mb ? "19px" : "30px")};
  width: ${(props: StylesProps) => {
    if (!props.size) {
      return "100%";
    }

    if (props.size === "low") {
      return "125px";
    } else if (props.size === "medium") {
      return "263px";
    }
  }};
  position: relative;
`;

export const FormLabel = styled.label`
  display: block;
  margin: 0 0 4px 8px;
  height: 22px;
  color: ${(props: StylesProps) =>
    props.validationError ? "#e51450" : "#3c3c3c"};
  font-family: "Avenir Next";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
`;

export const FormField = styled.input`
  width: 100%;
  box-sizing: border-box;
  height: 48px;
  border: ${(props: StylesProps) =>
    props.validationError ? "1px solid #e51450" : "1px solid #dcdcdc"};
  border-radius: 8px;
  background-color: #ffffff;
  padding-left: 14px;
  font-family: "Avenir Next";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 25px;
  outline: none;
  color: #444444;
`;

export const SelectField = styled.select`
  width: 100%;
  box-sizing: border-box;
  height: 48px;
  border: ${(props: StylesProps) =>
    props.validationError ? "1px solid #e51450" : "1px solid #dcdcdc"};
  border-radius: 8px;
  background-color: #ffffff;
  padding-left: 14px;
  font-family: "Avenir Next";
  font-size: ${(props: StylesProps) => (props.fontSizeLower ? "16px" : "18px")};
  letter-spacing: 0;
  line-height: ${(props: StylesProps) =>
    props.fontSizeLower ? "19px" : "21px"};
  outline: none;
  color: ${(props: StylesProps) => {
    if (props.default) {
      return "#444444";
    }

    return !props.selectValue || props.selectValue === "DEFAULT"
      ? "#6E6E6E"
      : "#444444";
  }};
  appearance: none;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 25px;
`;

export const SubmitButton = styled.input`
  height: 46px;
  width: 164px;
  border-radius: 6px;
  background-color: #00d6c2;
  color: #031E52;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  border: none;
  outline: none;
  margin-left: 13px;
  cursor: pointer;
`;

export const CancelButton = styled.button`
  box-sizing: border-box;
  background-color: #ffffff;
  height: 48px;
  width: 151px;
  border: 2px solid #b4b3b3;
  border-radius: 6px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  outline: none;
  cursor: pointer;
`;

export const CancelButtonText = styled.span`
  height: 22px;
  width: 135px;
  color: #626262;
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
`;

export const FieldsInRowContainer = styled.div`
  width: 400px;
  display: flex;
  justify-content: space-between;
`;

export const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #3c3c3c transparent transparent transparent;
  position: absolute;
  right: 21px;
  top: 47px;
`;

export const ErrorMessage = styled.p`
  margin: 5px 0 0 4px;
  height: 15px;
  width: 357px;
  color: #e51450;
  font-family: "Avenir Next";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.33px;
  line-height: 15px;
`;

export const DeleteContainer = styled.div`
  margin-top: 70px;
  display: flex;
  justify-content: flex-end;
`;

export const DeleteReminder = styled.p`
  display: inline-block;
  font-family: "Avenir Next";
  font-size: 14px;
  line-height: 16px;
  color: #00d6c2;
  text-align: right;
  cursor: pointer;
  margin-bottom: 29px;
`;
