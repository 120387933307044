import styled from "styled-components";
import { Link } from "react-router-dom";

import { StylesProps } from "./logIn.interfaces";

export const LogInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogInContainer = styled.div`
  width: 370px;
  display: flex;
  flex-direction: column;
  margin-bottom: 328px;
`;

export const LogInHeader = styled.h2`
  height: 36px;
  width: 343px;
  color: #00295b;
  font-family: "Avenir Next";
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
  margin: 119px 0 31px;
`;

export const LineContainer = styled.div`
  height: 19px;
  width: 354px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 36px;
`;

export const Line = styled.div`
  box-sizing: border-box;
  height: 2px;
  width: 144.42px;
  border: 1px solid #000000;
  opacity: 0.5;
`;

export const LineContainerText = styled.p`
  height: 19px;
  width: 23px;
  opacity: 0.5;
  color: #00295b;
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.76px;
  line-height: 19px;
`;

export const LogInForm = styled.form``;

export const FormFieldContainer = styled.div`
  width: 370px;
  height: 72px;
  position: ${(props: StylesProps) => (props.rel ? "relative" : "static")};
  margin-bottom: ${(props: StylesProps) =>
    props.validationError ? "30px" : "25px"};
  
  &:last-of-type {
    margin-bottom: 32px;
  }
`;

export const FormLabel = styled.label`
  display: block;
  height: 19px;
  color: ${(props: StylesProps) =>
    props.validationError ? "#E51450" : "#444444"};
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  margin: 0 0 5px 8px;
`;

export const FormField = styled.input`
  width: 370px;
  height: 46px;
  border: ${(props: StylesProps) =>
    props.validationError ? "1px solid #E51450" : "1px solid #b4b3b3"};
  border-radius: 8px;
  background-color: #ffffff;
  color: #444440;
  font-family: "Avenir Next";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  padding: 0 0 0 16px;
  outline: none;
  box-sizing: border-box;
  padding: 14px 0 15px 16px;

  &:focus {
    border-color: ${(props: StylesProps) =>
      props.validationError ? "#E51450" : "#00d6c2"};
  }

  &::placeholder {
    color: #949594;
    font-family: "Avenir Next";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
  }
`;

export const SubmitButtom = styled.input`
  width: 100%;
  height: 44px;
  border-radius: 6px;
  background: linear-gradient(45deg, #00c8aa 0%, #00dac2 100%);
  border: none;
  cursor: pointer;
  outline: none;
  color: #ffffff;
  font-family: "Avenir Next";
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 27px;
  margin-top: ${(props: StylesProps) => props.serverError ? "40px" : "0"};
`;

export const TermsContainer = styled.div`
  margin-bottom: 105px;
`;

export const Terms = styled.p`
  height: 26px;
  color: #00295b;
  font-family: "Avenir Next";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin: ${(props: StylesProps) => (props.firstTerm ? "40px 0 22px" : "0")};
`;

export const TermsLink = styled(Link)`
  font-weight: bold;
  text-decoration: none;
  color: #00295b;
`;

export const Eye = styled.img`
  position: absolute;
  height: 20px;
  width: 20px;
  right: 16px;
  bottom: 15px;
  cursor: pointer;
`;

export const InvalidDataError = styled.p`
  color: #e51450;
  font-family: "Avenir Next";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.33px;
  line-height: 15px;
  margin: 10px 0 30px 4px;
`;
