import React from "react";

import { DateOptionsListProps } from "./dateOptionsList.interfaces";

export const DateOptionsList: React.FC<DateOptionsListProps> = (props) => {
  const styles = {
    fontFamily: "Avenir Next",
    fontSize: "18px",
    lineHeight: "21px",
  };

  const optionsList = props.list.map((elem, index) => {
    return (
      <option key={index} value={elem} style={styles}>
        {elem}
      </option>
    );
  });

  return <>{optionsList}</>;
};
