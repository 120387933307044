import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Header } from "../../components/header/header.component";
import { Footer } from "../../components/footer/footer.component";
import { Button } from "../../components/button/button.component";
import { PageWrapper } from "../../components/wrapper/wrapper.component";
import { OldVerificationLink } from "../oldVerificationLink/oldVerificationLink.component";
import API from "../../services/authApi";

import {
  MainContent,
  MainContentHeader,
  MainContentText,
} from "./verifyNewEmail.styles";

export const ChangeEmailSuccess: React.FC = () => {
  const location = useLocation();
  const [isVerifyValid, setIsVerifyValid] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = location.search.slice(location.search.indexOf("=") + 1);

    const moveTokenToServer = async () => {
      try {
        const result = await API.post("/email-change-confirmation", null, {
          headers: { Authorization: token },
        });
        if (result) setIsLoading(false);
      } catch (error) {
        setIsVerifyValid(false);
        setIsLoading(false);
      }
    };

    
    moveTokenToServer();
  }, [location.search]);

  return (
    <>
      <Header />
      {!isLoading && (
        <PageWrapper>
          {isVerifyValid ? (
            <MainContent>
              <MainContentHeader>Email verified</MainContentHeader>
              <MainContentText>
                Your email was successfully verified. You can now log in with
                your new email address.
              </MainContentText>
              <Button text="Log in" />
            </MainContent>
          ) : (
            <OldVerificationLink buttonText="Log in" />
          )}
        </PageWrapper>
      )}
      <Footer />
    </>
  );
};
