import React from "react";
import { useDispatch } from "react-redux";

import {
  PageHeader,
  HeaderLine,
  PageHeaderGreen,
  HeaderNavigation,
  NavigationLinkContainer,
  NavigationLink,
  NavigationOutsideLink,
} from "./header.styles";
import { HeaderProps } from "./header.interfaces";
import { ROUTES } from "../../shared/constants/routes";
import { Logo } from "../logo/logo.component";
import { removeRemindersData } from "../../store/remindersList/actions/remindersList.actions";
import authAPI from "../../services/authApi";

export const Header: React.FC<HeaderProps> = (props) => {
  const dispatch = useDispatch();

  const onLogOutClick = () => {
    const refreshToken = localStorage.getItem("refresh");
    const accessToken = localStorage.getItem("token");

    const logOut = async () => {
      try {
        const resp = await authAPI.post(
          "/logout",
          { refresh: refreshToken },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        if (resp) {
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");

          dispatch(removeRemindersData());
        }
      } catch (error) {
        console.log(error.response);
      }
    };

    logOut();
  };

  const renderHeader = () => {
    if (props.isSignedIn) {
      return (
        <PageHeaderGreen>
          <Logo isSignedIn={true} />
          <HeaderNavigation>
            <NavigationLinkContainer>
              <NavigationLink to={ROUTES.remindersList}>
                Reminders
              </NavigationLink>
            </NavigationLinkContainer>

            <NavigationLinkContainer>
              <NavigationOutsideLink href="https://www.getreminded.com/get-help/" target="blank">
                Get help
              </NavigationOutsideLink>
            </NavigationLinkContainer>

            <NavigationLinkContainer>
              <NavigationLink to={ROUTES.accountSettings}>
                Profile
              </NavigationLink>
            </NavigationLinkContainer>

            <NavigationLinkContainer>
              <NavigationLink to={ROUTES.logIn} onClick={onLogOutClick}>
                Log out
              </NavigationLink>
            </NavigationLinkContainer>
          </HeaderNavigation>
        </PageHeaderGreen>
      );
    }

    return (
      <PageHeader>
        <Logo />
        <HeaderLine />
      </PageHeader>
    );
  };

  return renderHeader();
};
