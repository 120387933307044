import React from "react";
import Modal from "react-modal";

import { ChangeCountryAgreeProps } from "./askAgain.interfaces";
import {
  ModalHeader,
  ModalBody,
  SubmitButton,
  CancelButtonText,
  CancelButton,
  SubmitButtonText,
  ButtonsContainer,
} from "./askAgain.styles";

import "./modal.css";

Modal.setAppElement("#root");

export const AskAgain: React.FC<ChangeCountryAgreeProps> = (
  props
) => {
  return (
    <Modal
      isOpen={props.showModal}
      onRequestClose={props.handleCloseModal}
      className="modal-wrapper"
    >
      <ModalHeader>{props.headerText}</ModalHeader>
      <ModalBody>{props.bodyText}</ModalBody>
      <ButtonsContainer>
        <CancelButton onClick={props.cancelCallback}>
          <CancelButtonText>Cancel</CancelButtonText>
        </CancelButton>
        <SubmitButton onClick={props.submitCallback}>
          <SubmitButtonText>{props.submitButtonText}</SubmitButtonText>
        </SubmitButton>
      </ButtonsContainer>
    </Modal>
  );
};

