import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { SignUpErrorProps, RootState } from "./countrySelect.interfaces";
import {
  ModalHeader,
  ModalBody,
  CountryForm,
  FormSelectField,
  FormFieldContainer,
  SelectArrow,
  SubmitButtom,
  FormField,
  LocationIcon,
} from "./countrySelect.styles";
import { CountriesList } from "../../components/countriesList/countriesList.component";
import { SignUpPosctodeError } from "../../components/formsValidation/formsValidation.component";
import usersAPI from "../../services/usersApi";
import locationIcon from "../../assets/svg/location-icon.svg";
import { fetchCountriesAsync } from "../../store/countries/actions/countries.actions";
import { ROUTES } from "../../shared/constants/routes";

import "./modal.css";

Modal.setAppElement("#root");

export const CountrySelectModal: React.FC<SignUpErrorProps> = (props) => {
  const [postcodeValue, setPostcodeValue] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCountriesAsync.request());
  }, [dispatch]);

  const countries = useSelector(
    (state: RootState) => state.countries.countries
  );

  const { register, handleSubmit, errors, watch, control } = useForm({
    defaultValues: {
      country_id: "DEFAULT",
      postcode: "",
    },
  });

  const onFormSubmit = handleSubmit(async (data) => {
    const token = localStorage.getItem("token");

    const setCountry = async () => {
      await usersAPI.patch("/me/profile/edit", data, {
        headers: { Authorization: `Bearer ${token}` },
      });
    };

    setCountry();

    props.handleCloseModal();
    setTimeout(() => {
      history.push(ROUTES.remindersList);
    }, 300);
  });

  const onPostcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const postcode = event.target.value;

    if (/^[A-Za-z0-9 ]+$/.test(postcode) || !postcode) {
      setPostcodeValue(postcode);
    }
  };

  const renderMoval = () => {
    if (countries.length === 0) {
      return <></>;
    }

    return (
      <Modal isOpen={props.showModal} className="country-select-modal-wrapper">
        <LocationIcon src={locationIcon} alt="location-icon" />
        <ModalHeader>Confirm your location</ModalHeader>
        <ModalBody>
          Confirm your country and postcode so we can adjust your currency and
          give you relevant offers in your reminders.
        </ModalBody>
        <CountryForm onSubmit={onFormSubmit}>
          <FormFieldContainer validationError={errors.country_id} rel="true">
            <FormSelectField
              name="country_id"
              defaultValue={`DEFAULT`}
              validationError={errors.country_id}
              countryField={watch("country_id")}
              ref={register({
                validate: (value) => value !== "DEFAULT",
              })}
            >
              <option value="DEFAULT">Please select country</option>
              <CountriesList countries={countries} />
            </FormSelectField>
            <SelectArrow />
          </FormFieldContainer>

          <FormFieldContainer validationError={errors.postcode}>
            <Controller
              as={
                <FormField
                  placeholder="Postcode / Zip code"
                  validationError={errors.postcode}
                  maxLength={16}
                  value={postcodeValue || ""}
                  onChange={onPostcodeChange}
                />
              }
              name="postcode"
              control={control}
              rules={{
                validate: (value) =>
                  (value.length >= 4 && /^[A-Za-z0-9 ]+$/.test(value)) ||
                  value.length === 0,
              }}
            />
            {errors.postcode && <SignUpPosctodeError val={watch("postcode")} />}
          </FormFieldContainer>

          <SubmitButtom type="submit" value="Confirm" />
        </CountryForm>
      </Modal>
    );
  };

  return renderMoval();
};
