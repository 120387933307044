import { createReducer } from "typesafe-actions";

import {
  fetchReminderDataAsync,
  removeReminderData,
} from "./../actions/reminderData.actions";
import { StoreData } from "./reducers.interfaces";
import { ReminderInterface } from "../../../shared/interfaces/reminder.interface";

export const initialState = {
  reminderData: {},
  errors: "",
};

export const reminderDataReducer = createReducer(initialState)
  .handleAction(fetchReminderDataAsync.request, (state: StoreData) => ({
    ...state,
  }))
  .handleAction(
    fetchReminderDataAsync.success,
    (state: StoreData, action: { payload: ReminderInterface }) => {
      return { ...state, reminderData: action.payload };
    }
  )
  .handleAction(
    fetchReminderDataAsync.failure,
    (state: StoreData, action: { payload: string }) => ({
      ...state,
      errors: action.payload,
    })
  )
  .handleAction(removeReminderData, (state: StoreData) => ({
    ...state,
    reminderData: {},
    errors: "",
  }));
