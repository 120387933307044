import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Header } from "../../components/header/header.component";
import { Footer } from "../../components/footer/footer.component";
import { PageWrapper } from "../../components/wrapper/wrapper.component";
import {
  UnsubscribeContainer,
  UnsubscribeHeader,
  UnsubscribeBody,
  ReturnLinl,
  UnsubscribeWrapper,
} from "./unsubscribe.styles";
import { monthsArray } from "../../shared/constants/months";

import usersAPI from "../../services/usersApi";

export const UnsubscribeSuccess: React.FC = () => {
  const [serverError, setServerError] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const keyStart = "key=";
    const userKey = location.search.slice(
      location.search.indexOf(keyStart) + keyStart.length
    );

    const setToken = async () => {
      try {
        await usersAPI.post("/unsubscribe", null, {
          headers: { Authorization: userKey },
        });
      } catch (error) {
        setServerError("The link is expired");
      }

      setIsLoaded(true);
    };

    setToken();
  }, [location.search]);

  return (
    <>
      <Header />
      <PageWrapper>
        {isLoaded && (
          <UnsubscribeWrapper>
            <UnsubscribeContainer>
              <UnsubscribeHeader>
                Subscribers with No Reminder - {new Date().getDate()}{" "}
                {monthsArray[new Date().getMonth()]}
              </UnsubscribeHeader>
              <UnsubscribeBody errorMessage={serverError}>
                {serverError ? serverError : "Unsubscribe Successful"}
              </UnsubscribeBody>
              <ReturnLinl to="/">{`<<`} return to our website</ReturnLinl>
            </UnsubscribeContainer>
          </UnsubscribeWrapper>
        )}
      </PageWrapper>
      <Footer />
    </>
  );
};
