import React from "react";

import {
  FooterContainer,
  Skyline2Container,
  Skyline2Shadow,
  Line,
  FooterNav,
  Navigation,
  NavigationItem,
  Social,
  SocialItem,
  CopyRight,
  ImageContainer,
} from "./footer.styles";

import facebookIcon from "../../assets/svg/Facebook-color.svg";
import instagramIcon from "../../assets/svg/Instagram-color.svg";

export const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <Skyline2Container>
        <Skyline2Shadow />
      </Skyline2Container>
      <Line />
      <FooterNav>
        <Navigation>
          <NavigationItem
            as="a"
            href="https://www.getreminded.com/about-getreminded/"
          >
            About
          </NavigationItem>
          <NavigationItem as="a" href="https://www.getreminded.com/faq/">
            FAQ
          </NavigationItem>
          <NavigationItem as="a" href="https://www.getreminded.com/get-help/">
            Get Help
          </NavigationItem>
          <NavigationItem
            as="a"
            href="https://www.getreminded.com/privacy-policy/"
          >
            Privacy
          </NavigationItem>
          <NavigationItem
            as="a"
            href="https://www.getreminded.com/terms-of-service/"
          >
            Terms of Service
          </NavigationItem>
        </Navigation>
        <Social>
          <ImageContainer href="https://www.facebook.com/GetReminded/">
            <SocialItem src={facebookIcon} alt="facebook" />
          </ImageContainer>
          <ImageContainer href="https://www.instagram.com/getreminded/">
            <SocialItem src={instagramIcon} alt="instagram" />
          </ImageContainer>
        </Social>
      </FooterNav>
      <Line />
      <CopyRight>© 2020 GetReminded Pty Ltd. ABN 83 620 016 604.</CopyRight>
    </FooterContainer>
  );
};
