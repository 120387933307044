import React from "react";

import { AdvertisementItemProps } from "./advertisementItem.interfaces";
import {
  Advertisement,
  AdvertisementIcon,
  AdvertisementTextContainer,
  AdvertisementHeaderText,
  AdvertisementBodyText,
  ArrowRight,
} from "./advertisementItem.styles";
import arrowRight from '../../../../assets/svg/moreArrowRight.svg';

export const AdvertisementItem: React.FC<AdvertisementItemProps> = ({
  icon,
  headerText,
  bodyText,
  link,
  onClick,
}) => {
  return (
    <Advertisement onClick={onClick} href={link} target="_blank">
      <AdvertisementIcon src={icon} alt="Advertisement-icon" />
      <AdvertisementTextContainer>
        <AdvertisementHeaderText>{headerText}</AdvertisementHeaderText>
        <AdvertisementBodyText>{bodyText}</AdvertisementBodyText>
      </AdvertisementTextContainer>
      <ArrowRight src={arrowRight} alt="arrow" />
    </Advertisement>
  );
};
