import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Header } from "../../components/header/header.component";
import { AccountSettingsForm } from "../../components/accountSettingsForm/accountSettingsForm.component";
import { PageWrapper, PageHeader, Line } from "./accountSettings.styles";
import { RootState } from "./accountSettings.interfaces";
import { fetchUserDataAsync } from "../../store/userData/actions/userData.actions";
import { fetchCountriesAsync } from "../../store/countries/actions/countries.actions";
import { refreshToken } from "../../shared/utils/refreshToken";
import { ServerError } from "../../components/serverError/serverError.components";
import { ROUTES } from "../../shared/constants/routes";

export const AccountSettings: React.FC = () => {
  const dispatch = useDispatch();
  const UserData = useSelector((state: RootState) => state.userData.userData);
  const countries = useSelector(
    (state: RootState) => state.countries.countries
  );
  const history = useHistory();

  useEffect(() => {
    if (!UserData.country) {
      dispatch(fetchUserDataAsync.request());
    }

    if (countries.length === 0) {
      dispatch(fetchCountriesAsync.request());
    }
  }, [UserData.country, countries.length, dispatch]);

  const userDataError = useSelector(
    (state: RootState) => state.userData.errors
  );

  const onCancelButtonClick = () => {
    history.push(ROUTES.remindersList);
  };

  const renderForm = () => {
    if (UserData.country && countries.length !== 0) {
      return (
        <AccountSettingsForm
          userData={UserData}
          countries={countries}
          onCancelButtonClick={onCancelButtonClick}
        />
      );
    }

    return <></>;
  };

  const checkData = () => {
    if (userDataError) {
      const refresh = refreshToken();

      if (refresh) {
        history.push(ROUTES.logIn);
      }
    }
  };

  const renderContent = () => {
    if (+userDataError >= 500 && +userDataError === 404) {
      return <ServerError code={userDataError} />;
    }

    return (
      <>
        {checkData()}
        <Header isSignedIn />
        <PageWrapper>
          <PageHeader>Account Settings</PageHeader>
          <Line />
          {renderForm()}
        </PageWrapper>
      </>
    );
  };

  return renderContent();
};
