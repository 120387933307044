import { takeEvery, put, call } from "redux-saga/effects";

import { fetchReminderDataAsync } from "./../actions/reminderData.actions";
import API from "../../../services/api";

export async function fetchReminderData(id: string) {
  const userToken = localStorage.getItem("token");

  const reminderData = await API.get(`/reminders/${id}`, {
    headers: { Authorization: `Bearer ${userToken}` },
  });

  if (reminderData) {
    return await reminderData.data;
  } else {
    return Promise.reject();
  }
}

export function* workerFetchReminderData(data: { payload: string }) {
  const id = data.payload;

  try {
    const reminderData = yield call(fetchReminderData, id);

    yield put(fetchReminderDataAsync.success(reminderData));
  } catch (error) {
    yield put(fetchReminderDataAsync.failure(error.response.status.toString()));
  }
}

export function* reminderDataSaga() {
  yield takeEvery(fetchReminderDataAsync.request, workerFetchReminderData);
}
