import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector, batch } from "react-redux";

import { Header } from "../../components/header/header.component";
import {
  PageWrapper,
  PageHeaderContainer,
  PageHeaderText,
  PageHeaderButton,
  ButtonText,
  RemindersCostContainer,
  RemindersConstPrice,
  HorisontalLine,
  NoRemindersContainer,
  NoRemindersIcon,
  NoRemindersText,
  RemindersContainer,
  RemindersWrapper,
} from "./remindersList.styles";
import { ReminderCategory } from "../../components/reminderCategory/reminderCategory.component";
import { RemindersCategoriesList } from "../../components/remindersCategoriesList/remindersCategoriesList.component";
import { Reminder } from "../../components/reminder/reminder.component";
import {
  fetchRemindersCategoriesAsync,
  fetchRemindersCostAsync,
  fetchRemindersListAsync,
} from "../../store/remindersList/actions/remindersList.actions";
import { fetchUserDataAsync } from "../../store/userData/actions/userData.actions";
import { removeReminderData } from "../../store/reminderData/actions/reminderData.actions";
import { ROUTES } from "../../shared/constants/routes";
import { setExpiryDateFormat } from "./utils/setExpiryDateFormat";
import { RootState, Survey } from "./remindersList.interfaces";
import { refreshToken } from "../../shared/utils/refreshToken";
import { ServerError } from "../../components/serverError/serverError.components";
import { CustomerSurveyMessage } from "../../components/customerSurveyMessage/customerSurveyMessage.component";
import API from "../../services/api";
import {DateTime} from 'luxon';
import { ReminderInterface } from "../../shared/interfaces/reminder.interface";

export const RemindersList: React.FC = () => {
  const [selectedElement, setSelectedElement] = useState("all");

  const defaultSurveyValues = {
    id: 0,
    title: "",
    main_text: "",
    link: "",
    link_label: "",
    start_at: "",
    end_at: "",
    create_time: "",
    update_time: "",
  };

  const [survey, setSurvey] = useState<Survey>(defaultSurveyValues);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    batch(() => {
      dispatch(fetchRemindersCategoriesAsync.request());
      dispatch(fetchRemindersCostAsync.request());
      dispatch(fetchRemindersListAsync.request());
      dispatch(fetchUserDataAsync.request());
      dispatch(removeReminderData());
    });

    const getSurveyData = async () => {
      const userToken = localStorage.getItem("token");

      try {
        const resp = await API.get("/customer-survey", {
          headers: { Authorization: `Bearer ${userToken}` },
        });

        if (resp) {
          setSurvey(resp.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getSurveyData();
  }, [dispatch]);

  const remindersCategories = useSelector(
    (state: RootState) => state.remindersList.remindersListStore
  );
  const remindersCost = useSelector(
    (state: RootState) => state.remindersList.remindersCost
  );
  const remindersList = useSelector(
    (state: RootState) => state.remindersList.remindersList
  );
  const userData = useSelector((state: RootState) => state.userData.userData);

  const userDataError = useSelector(
    (state: RootState) => state.userData.errors
  );

  const getSelectedElement = (elem: string) => {
    setSelectedElement(elem);
  };

  const onNewReminderClick = () => {
    history.push(ROUTES.newReminder);
  };

  const onCrossClick = () => {
    setSurvey(defaultSurveyValues);
  };

  const renderRemindersCategoriesList = () => {
    if (remindersCategories.length === 0) {
      return;
    }

    return remindersCategories.map((reminderCategory) => {
      return (
        <ReminderCategory
          itemKey={reminderCategory.id}
          selectedItem={selectedElement}
          key={reminderCategory.id}
          icon={
            reminderCategory.id === +selectedElement
              ? reminderCategory.white_icon
              : reminderCategory.coloured_icon
          }
          title={reminderCategory.name}
        />
      );
    });
  };

  const checkDate = (a: Date, b: Date) => {
     a = new Date(a)
     b = new Date(b)
    const a1 = DateTime.fromJSDate(a)
    const b1 = DateTime.fromJSDate(b)
    const diff = a1.diff(b1, ["days"]).toObject()
    return Math.ceil(diff.days as number)
  };

  const renderMainContent = () => {
    if (
      remindersCategories &&
      remindersCost.categories &&
      remindersList.act_now &&
      remindersList.act_later &&
      userData.email &&
      remindersCategories.length !== 0
    ) {
      const [selectedReminderCategory] = remindersCategories.filter(
        (elem) => elem.id === +selectedElement
      );

      const selectedCategoryActNow = remindersList.act_now.filter(
        (elem) => elem.category === +selectedElement
      );
      const selectedCategoryActLater = remindersList.act_later.filter(
        (elem) => elem.category === +selectedElement
      );
        const allReminders: ReminderInterface[] = []
        allReminders.push(...remindersList.act_later)
        allReminders.push(...remindersList.act_now)

        allReminders.forEach(reminder => {
          const time = new Date(reminder.expiry_date)
          const dayDiff = checkDate(time, new Date())
          reminder.days_to_expiration = dayDiff.toString()
        })

        allReminders.sort((a, b) => +a.days_to_expiration - +b.days_to_expiration);


        const allRemindersRender = allReminders.map(reminder => {
          const [reminderCategory] = remindersCategories.filter(
            (category) => category.id === reminder.category
          );



          let renderTime;

            if (+reminder.days_to_expiration < 0) {
              renderTime = +reminder.days_to_expiration === 0 
              ? "0" + reminder.days_to_expiration
              : '00'
            } else if (+reminder.days_to_expiration <= 45) {
            renderTime = +reminder.days_to_expiration < 10
            ? 0 + reminder.days_to_expiration
            : reminder.days_to_expiration;
          } else  {
            renderTime = setExpiryDateFormat(reminder.expiry_date)
          }

          return (
            <Reminder 
            key={reminder.id}
            id={reminder.id}
            icon={reminderCategory ? reminderCategory.coloured_icon : ""}
            cost={reminder.annual_cost}
            time={renderTime}
            reminderType={reminder.type.name}
            reminderProvider={reminder.provider ? reminder.provider.name : ''}
            currency={userData.country.currency}
            otherProvider={reminder.other_provider || "Other"}
            />
          );
        })

      if (!selectedReminderCategory) {
        if (remindersList.act_now.length !== 0) {
          remindersList.act_now.map((reminder) => {
            const [reminderCategory] = remindersCategories.filter(
              (category) => category.id === reminder.category
            );

            return (
              <Reminder
                key={reminder.id}
                id={reminder.id}
                icon={reminderCategory ? reminderCategory.coloured_icon : ""}
                cost={reminder.annual_cost}
                time={
                  +reminder.days_to_expiration < 10
                    ? 0 + reminder.days_to_expiration
                    : reminder.days_to_expiration
                }
                reminderType={reminder.type.name}
                reminderProvider={reminder.provider ? reminder.provider.name : ''}
                currency={userData.country.currency}
                otherProvider={reminder.other_provider || "Other"}
              />
            );
          });
        }

        if (remindersList.act_later.length !== 0) {
          allReminders.forEach(reminderList => {
            remindersList.act_later.map((reminder) => {
              const [reminderCategory] = remindersCategories.filter(
                (category) => category.id === reminder.category
              );
              const time = new Date(reminder.expiry_date)
                
                checkDate(time, new Date())
  
              return (
                <Reminder
                  key={reminder.id}
                  id={reminder.id}
                  icon={reminderCategory ? reminderCategory.coloured_icon : ""}
                  cost={reminder.annual_cost}
                  time={setExpiryDateFormat(reminder.expiry_date)}
                  reminderType={reminder.type.name}
                  reminderProvider={reminder.provider ? reminder.provider.name : ''}
                  currency={userData.country.currency}
                  otherProvider={reminder.other_provider || "Other"}
                  later={+reminderList.days_to_expiration > 45 ? true : false}
                />
              );
            });
          }) 

        }

       if (!!allReminders.length) {
          return (
            <RemindersWrapper>

              <RemindersContainer>{allRemindersRender}</RemindersContainer>
            </RemindersWrapper>
          );
        }

        return (
          <NoRemindersContainer>
            <NoRemindersText green>No reminders</NoRemindersText>
            <PageHeaderButton green onClick={onNewReminderClick}>
              <ButtonText green>New reminder</ButtonText>
            </PageHeaderButton>
          </NoRemindersContainer>
        );
      }

      if (
        selectedCategoryActNow.length > 0 ||
        selectedCategoryActLater.length > 0
      ) {
        const actNow = selectedCategoryActNow.map((reminder) => {
          let renderTime;

            if (+reminder.days_to_expiration < 0) {
              renderTime = +reminder.days_to_expiration === 0 
              ? "0" + reminder.days_to_expiration
              : '00'

            } else if (+reminder.days_to_expiration <= 45) {
            renderTime = +reminder.days_to_expiration < 10
            ? 0 + reminder.days_to_expiration
            : reminder.days_to_expiration;
          } else  {
            renderTime = setExpiryDateFormat(reminder.expiry_date)
            
          }

          return (
            <Reminder
              key={reminder.id}
              id={reminder.id}
              icon={selectedReminderCategory.coloured_icon}
              title={selectedReminderCategory.name}
              cost={reminder.annual_cost}
              time={renderTime}
              reminderType={reminder.type.name}
              reminderProvider={reminder.provider ? reminder.provider.name : ''}
              currency={userData.country.currency}
              otherProvider={reminder.other_provider || "Other"}
            />
          );
        });
        
        const actLater = selectedCategoryActLater.map((reminder) => {
        let renderTime;

            if (+reminder.days_to_expiration < 0) {
              renderTime = +reminder.days_to_expiration === 0 
              ? "0" + reminder.days_to_expiration
              : '00'

            } else if (+reminder.days_to_expiration <= 45) {
            renderTime = +reminder.days_to_expiration < 10
            ? 0 + reminder.days_to_expiration
            : reminder.days_to_expiration;
          } else  {
            renderTime = setExpiryDateFormat(reminder.expiry_date)
            
          }
          return (
            <Reminder
              key={reminder.id}
              id={reminder.id}
              icon={selectedReminderCategory.coloured_icon}
              title={selectedReminderCategory.name}
              cost={reminder.annual_cost}
              time={renderTime}
              reminderType={reminder.type.name}
              reminderProvider={reminder.provider ? reminder.provider.name : ''}
              currency={userData.country.currency}
              otherProvider={reminder.other_provider || "Other"}
              later
            />
          );
        });
        
        const sortingReminder = []
        sortingReminder.push(...actLater);
        sortingReminder.push(...actNow)
        let newArray:any = []
        let numberArray:any = []
        // eslint-disable-next-line
        const array = sortingReminder.map(item => {
          const itemLenght = item.props.time
          if((itemLenght.split(" ").length - 1) > 0){
            newArray.push(item)
          }else {
            numberArray.push(item)
          }
        })

        
        numberArray.sort((a:any, b:any) => +a.props.time - b.props.time)
        newArray.sort((a:any, b:any) => +new Date(a.props.time) - +new Date(b.props.time))
        
        
 
        return (
          <RemindersWrapper>
          {(numberArray && newArray)  && (
            <>
              <RemindersContainer>{numberArray}{newArray}</RemindersContainer>
            </>
          )}
          {/* {newArray && (
            <>
              <RemindersContainer>{newArray}</RemindersContainer>
            </>
          )} */}
        </RemindersWrapper>

        );
      }

      return (
        <NoRemindersContainer>
          <NoRemindersIcon
            src={selectedReminderCategory.grey_icon}
            alt="reminder category"
          />
          <NoRemindersText>
            No reminders in {selectedReminderCategory.name}
          </NoRemindersText>
          <PageHeaderButton green onClick={onNewReminderClick}>
            <ButtonText green>New reminder</ButtonText>
          </PageHeaderButton>
        </NoRemindersContainer>
      );
    } else {
      return <NoRemindersContainer />;
    }
  };

  const checkData = () => {
    if (userDataError) {
      const refresh = refreshToken();

      if (refresh) {
        history.push(ROUTES.logIn);
      }
    }
  };

  const renderContent = () => {
    if (+userDataError >= 500 && +userDataError === 404) {
      return <ServerError code={userDataError} />;
    }

    return (
      <>
        {checkData()}
        <Header isSignedIn={true} />
        {survey.id !== 0 && survey.id && (
          <CustomerSurveyMessage survey={survey} onCrossClick={onCrossClick} />
        )}
        <PageWrapper survey={survey.id ? true : false}>
          <PageHeaderContainer>
            <PageHeaderText>Reminders</PageHeaderText>
            <PageHeaderButton onClick={onNewReminderClick}>
              <ButtonText>New reminder</ButtonText>
            </PageHeaderButton>
          </PageHeaderContainer>
          <RemindersCostContainer>
            Total cost per year
            <RemindersConstPrice>
              {remindersCost.total_annual_cost}{" "}
              {userData.country ? userData.country.currency : ""}
            </RemindersConstPrice>
          </RemindersCostContainer>
          <RemindersCategoriesList
            renderRemindersCategoriesList={renderRemindersCategoriesList}
            getSelectedElement={getSelectedElement}
          />
          <HorisontalLine />
          {renderMainContent()}
        </PageWrapper>
      </>
    );
  };

  return renderContent();
};
