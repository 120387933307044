import styled from "styled-components";
import { Link } from "react-router-dom";

import { StylesProps } from "./forgotPassword.interfaces";

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MainContentWrapper = styled.div`
  width: 370px;
  margin-top: 129px;
  display: flex;
  flex-direction: column;
  margin-bottom: 328px;
`;

export const MainContentHeader = styled.h2`
  height: 36px;
  width: 343px;
  color: #00295b;
  font-family: "Avenir Next";
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 36px;
  margin-bottom: 24px;
`;

export const MainContentText = styled.p`
  height: 44px;
  width: 342px;
  color: #00295b;
  font-family: "Avenir Next";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 21px;
`;

export const ForgotPasswordForm = styled.form``;

export const FormFieldContainer = styled.div`
  width: 370px;
  height: 72px;
  position: ${(props: StylesProps) => (props.rel ? "relative" : "static")};
  margin-bottom: ${(props: StylesProps) =>
    props.validationError ? "30px" : "25px"};

  &:last-of-type {
    margin-bottom: 32px;
  }
`;

export const FormLabel = styled.label`
  display: block;
  height: 19px;
  color: ${(props: StylesProps) =>
    props.validationError ? "#E51450" : "#444444"};
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  margin: 0 0 5px 8px;
`;

export const FormField = styled.input`
  width: 370px;
  height: 46px;
  border: ${(props: StylesProps) =>
    props.validationError ? "1px solid #E51450" : "1px solid #b4b3b3"};
  border-radius: 8px;
  background-color: #ffffff;
  color: #444440;
  font-family: "Avenir Next";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  padding: 0 0 0 16px;
  outline: none;
  box-sizing: border-box;
  padding: 14px 0 15px 16px;

  &:focus {
    border-color: ${(props: StylesProps) =>
      props.validationError ? "#E51450" : "#00d6c2"};
  }

  &::placeholder {
    color: #949594;
    font-family: "Avenir Next";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
  }
`;

export const SubmitButtom = styled.input`
  width: 100%;
  height: 44px;
  border-radius: 6px;
  background: linear-gradient(45deg, #00c8aa 0%, #00dac2 100%);
  border: none;
  cursor: pointer;
  outline: none;
  color: #ffffff;
  font-family: "Avenir Next";
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 27px;
  margin-top: ${(props: StylesProps) => props.serverError?.startsWith('Sorry') ? "20px" : "0"};
`;

export const BackToLogin = styled(Link)`
  height: 26px;
  width: 238px;
  color: #00295b;
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  margin-top: 37px;
  align-self: center;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 350px;
`;

export const InvalidEmailError = styled.p`
  color: #e51450;
  font-family: "Avenir Next";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.33px;
  line-height: 15px;
  margin: 10px 0 30px 4px;
`;
