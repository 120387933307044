import styled from "styled-components";

export const MessageContainer = styled.div`
  width: 100%;
  background-color: rgba(0, 233, 184, 0.13);
  padding-top: 16px;
  padding-bottom: 29px;
`;

export const MessageWrapper = styled.div`
  margin: 0 18px 0 74px;
`;

export const MessageHeaderContainer = styled.div`
  position: relative;
`;

export const MessageHeader = styled.h3`
  color: rgba(0, 0, 0, 0.6);
  font-family: "Avenir Next";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 8px;
  margin-right: 56px;
  word-wrap: break-word;
`;

export const MessageBody = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-family: "Avenir Next";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 18px;
  margin-right: 56px;
  word-wrap: break-word;
`;


export const MessageLink = styled.a`
  display: inline-flex;
  align-items: center;
  height: 19px;
  color: #00d6c2;
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-decoration: none;
`

export const Arrow = styled.img`
  height: 24px;
  width: 24px;
  margin-left: 4px;
`;

export const Cross = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
`;