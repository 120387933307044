import { takeEvery, put, call } from "redux-saga/effects";

import { fetchCountriesAsync } from "./../actions/countries.actions";
import API from "../../../services/api";

export async function fetchCountries() {
  const countries = await API.get("/countries");

  if (countries) {
    return await countries.data;
  } else {
    return Promise.reject();
  }
}

export function* workerFetchCountries() {
  try {
    const countries = yield call(fetchCountries);

    yield put(fetchCountriesAsync.success(countries));
  } catch (error) {
    yield put(fetchCountriesAsync.failure(error.response.status.toString()));
  }
}

export function* countriesSaga() {
  yield takeEvery(fetchCountriesAsync.request, workerFetchCountries);
}
