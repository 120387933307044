import styled from "styled-components";
import { Link } from "react-router-dom";

import { StylesProps } from "./editReminder.interfaces";

export const PageWrapper = styled.div`
  background-color: #f2f2f2;
  padding: 0 74px;
  min-height: 100vh;
  padding-bottom: 35px;
`;

export const BackLink = styled(Link)`
  height: 22px;
  display: inline-block;
  width: 137px;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Avenir Next";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  margin: 41px 0 19px;
  text-decoration: none;
`;

export const PageHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const ReminderTitle = styled.p`
  height: 48px;
  color: #00295b;
  font-family: Avenir;
  font-size: 45px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 48px;
`;

export const CategoryIconContainer = styled.div`
  width: 64px;
  height: 60px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 17px;
`;

export const CategoryIcon = styled.img`
  width: 32px;
  height: 32px;
`;

export const SeparateLine = styled.div`
  box-sizing: border-box;
  height: 2px;
  width: 100%;
  border: 1px solid #d2d2d2;
  margin: 40.75px 0 35.25px;
`;

export const TimeContainer = styled.div`
  position: absolute;
  text-align: right;
  right: 0;
  margin-right: 10px;
`;

export const TimeValue = styled.p`
  width: 26px;
  height: 20px;
  font-family: Oswald;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  position: absolute;
  top: 1px;
  right: 3px;
`;

export const TimeTitle = styled.p`
  height: 8px;
  color: ${(props: StylesProps) => (+props.time <= 10 ? "#d9006c" : "#FEC776")};
  font-family: "Avenir Next";
  font-size: 9.6px;
  font-weight: 600;
  letter-spacing: 0.36px;
  line-height: 14px;
  width: 100%;
  text-align: right;
  padding-right: 3px;
  box-sizing: border-box;
`;

export const ReminderTimeIcon = styled.img`
  width: 32px;
  height: 32px;
`;

export const ActLaterText = styled.p`
  width: 179px;
  color: #787878;
  font-family: "Avenir Next";
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  text-align: right;
  position: absolute;
  right: 4px;
`;
