import styled from "styled-components";

export const MainContent = styled.div`
  height: 392px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 360px;
`;

export const MainContentHeader = styled.h2`
  height: 36px;
  width: 343px;
  color: #00295b;
  font-family: "Avenir Next";
  font-weight: 700;
  font-size: 26px;
  letter-spacing: 0;
  line-height: 36px;
  text-align: center;
  margin: 116px 0 12px;
`;

export const MainContentText = styled.p`
  width: 343px;
  height: 50px;
  color: #00295b;
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-bottom: 49px;
`;
