import styled from "styled-components";
import { StylesProps } from "./newReminder.interfaces";

export const PageWrapper = styled.div`
  background-color: #f2f2f2;
  padding: 0 74px;
  min-height: 100vh;
  padding-bottom: 35px;
`;

export const StepButton = styled.div`
  height: 20px;
  width: 84px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  border: ${(props: StylesProps) =>
    !props.activeStep ? "1px solid #C6CBD4" : "none"};
  background: ${(props: StylesProps) =>
    props.activeStep
      ? "linear-gradient(45deg, #32198c 0%, #8e00e6 100%)"
      : "#f2f2f2"};
  box-sizing: border-box;
  margin-top: 49px;
  text-align: center;
  margin-right: 13px;
  cursor: pointer;
`;

export const StepButtonText = styled.span`
  color: ${(props: StylesProps) => (props.activeStep ? "#ffffff" : "#C6CBD4")};
  font-family: "Avenir Next";
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  text-transform: uppercase;
`;

export const PageHeader = styled.h1`
  height: 48px;
  width: 307px;
  color: #00295b;
  font-family: Avenir;
  font-size: 45px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 48px;
  margin: 14px 0 43px;
`;
