import styled from 'styled-components';

export const SubmitButtonWrapper = styled.input`
  width: 370px;
  height: 44px;
  border-radius: 6px;
  background: linear-gradient(45deg, #00c8aa 0%, #00dac2 100%);
  border: none;
  cursor: pointer;
  outline: none;
  color: #ffffff;
  font-family: "Avenir Next";
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 27px;
`;