import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';

import { Header } from "../../components/header/header.component";
import { Footer } from "../../components/footer/footer.component";
import { PageWrapper } from "../../components/wrapper/wrapper.component";
import { SocialButton } from "../../components/socialButton/socialButton.component";
import facebookSignUpIcon from "../../assets/svg/facebook-sign-in.svg";
import googleSignUpIcon from "../../assets/svg/google-sign-in.svg";
import {
  LogInWrapper,
  LogInContainer,
  LogInHeader,
  LineContainer,
  Line,
  LineContainerText,
  LogInForm,
  FormFieldContainer,
  FormLabel,
  FormField,
  SubmitButtom,
  TermsContainer,
  Terms,
  TermsLink,
  Eye,
  InvalidDataError,
} from "./logIn.styles";
import { ROUTES } from "../../shared/constants/routes";
import {
  SignUpEmailError,
  SignUpPasswordValidation,
} from "../../components/formsValidation/formsValidation.component";
import authAPI from "../../services/authApi";
import eyeIcon from "../../assets/img/eye.png";

export const LogIn: React.FC = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();

  const { register, handleSubmit, errors, watch } = useForm();

  const onFormSubmit = handleSubmit(async (data) => {
    const setToServer = async () => {
      try {
        const resp = await authAPI.post("/token/", data);

        if (resp) {
          localStorage.setItem("token", resp.data.access);
          localStorage.setItem("refresh", resp.data.refresh);

          history.push(ROUTES.remindersList);
        }
      } catch (error) {
        const serverMessage = error.request.response;

        await setErrorMessage(
          serverMessage.slice(
            serverMessage.indexOf("[") + 2,
            serverMessage.length - 4
          )
        );
      }
    };

    setToServer();
  });

  const onEyeClick = () => {
    setPasswordVisible(!passwordVisible);
  };

  const onInputChange = () => {
    setErrorMessage("");
  };

  return (
    <>
      <Header />
      <PageWrapper>
        <LogInWrapper>
          <LogInContainer>
            <LogInHeader>Log in</LogInHeader>
            <SocialButton
              social="facebook"
              icon={facebookSignUpIcon}
              privacy={true}
            />
            <SocialButton
              social="google"
              icon={googleSignUpIcon}
              privacy={true}
            />
            <SocialButton
              social="apple"
              privacy={true}
            />

            <LineContainer>
              <Line />
              <LineContainerText>OR</LineContainerText>
              <Line />
            </LineContainer>

            <LogInForm onSubmit={onFormSubmit}>
              <FormFieldContainer validationError={errors.email}>
                <FormLabel validationError={errors.email}>Email</FormLabel>
                <FormField
                  name="email"
                  placeholder="User@email.com"
                  maxLength={64}
                  validationError={errors.email}
                  onChange={onInputChange}
                  ref={register({
                    validate: (value) =>
                      value.length > 0 &&
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        value
                      ),
                  })}
                />
                {errors.email && <SignUpEmailError val={watch("email")} />}
              </FormFieldContainer>

              <FormFieldContainer validationError={errors.password} rel="true">
                <FormLabel validationError={errors.password}>
                  Password
                </FormLabel>
                <Eye src={eyeIcon} onClick={onEyeClick} />
                <FormField
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  maxLength={20}
                  onChange={onInputChange}
                  placeholder="Minimum 6 characters"
                  validationError={errors.password}
                  ref={register({
                    validate: (value) =>
                      value.length >= 6 &&
                      value.length <= 20 &&
                      !value.includes(" "),
                  })}
                />
                {errors.password && (
                  <SignUpPasswordValidation val={watch("password")} />
                )}
                {errorMessage && (
                  <InvalidDataError>{errorMessage}</InvalidDataError>
                )}
              </FormFieldContainer>

              <SubmitButtom
                type="submit"
                value="Log in"
                serverError={errorMessage}
              />
            </LogInForm>
            <TermsContainer>
              <Terms firstTerm>
                <TermsLink to={ROUTES.forgotPassword}>
                  Forgot password?
                </TermsLink>
              </Terms>
              <Terms>
                Don't have an account?{" "}
                <TermsLink to={ROUTES.signUp}>Sign up</TermsLink>
              </Terms>
            </TermsContainer>
          </LogInContainer>
        </LogInWrapper>
      </PageWrapper>
      <Footer />
    </>
  );
};
