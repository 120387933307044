import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { Header } from "../../components/header/header.component";
import {
  PageWrapper,
  BackLink,
  PageTitle,
  SeparateLine,
  PasswordForm,
  FormFieldContainer,
  FormLabel,
  FormField,
  ButtonsContainer,
  SubmitButton,
  CancelButton,
  CancelButtonText,
  ErrorMessage,
} from "./changePassword.styles";
import {
  SignUpPasswordValidation,
  SignUpConfirmPasswordValidation,
} from "../../components/formsValidation/formsValidation.component";
import { ROUTES } from "../../shared/constants/routes";
import authAPI from '../../services/authApi';

export const ChangePassword: React.FC = () => {
  const [oldPaswError, setOldPaswError] = useState('');
  const [newPaswError, setNewPaswError] = useState('');

  const { register, handleSubmit, errors, watch } = useForm();

  const history = useHistory();

  const onFormSubmit = handleSubmit((data) => {
    const userToken = localStorage.getItem("token");

    const setData = async () => {
      try {
        const resp = await authAPI.patch("change-password", data, {
          headers: { Authorization: `Bearer ${userToken}` },
        });

        if (resp) {
          history.push(ROUTES.accountSettings);
        } 
      } catch (error) {
        setOldPaswError(error.response.data.detail.old_password || "");
        setNewPaswError(error.response.data.detail.new_password || "");
      }
    };

    setData();
  });

  const onCancelButtonClick = () => {
    history.push(ROUTES.accountSettings);
  };

  const onOldPaswChange = () => {
    if (oldPaswError) {
      setOldPaswError("");
    }

    if (newPaswError) {
      setNewPaswError("");
    }
  }

  const onNewPaswChange = () => {
    if (newPaswError) {
      setNewPaswError("");
    }
  }

  const renderForm = () => {
    return (
      <PasswordForm onSubmit={onFormSubmit}>
        <FormFieldContainer>
          <FormLabel validationError={errors.old_password}>
            Current password
          </FormLabel>
          <FormField
            type="password"
            name="old_password"
            maxLength={20}
            validationError={errors.old_password}
            onChange={onOldPaswChange}
            ref={register({
              validate: (value) =>
                value.length >= 6 && value.length <= 20 && !value.includes(" "),
            })}
          />
          {oldPaswError && <ErrorMessage>{oldPaswError}</ErrorMessage>}
          {errors.old_password && (
            <SignUpPasswordValidation val={watch("old_password")} />
          )}
        </FormFieldContainer>

        <FormFieldContainer>
          <FormLabel validationError={errors.new_password}>
            New password
          </FormLabel>
          <FormField
            type="password"
            name="new_password"
            maxLength={20}
            validationError={errors.new_password}
            onChange={onNewPaswChange}
            ref={register({
              validate: (value) =>
                value.length >= 6 && value.length <= 20 && !value.includes(" "),
            })}
          />
          {errors.new_password && (
            <SignUpPasswordValidation val={watch("new_password")} />
          )}
        </FormFieldContainer>

        <FormFieldContainer>
          <FormLabel validationError={errors.confirm_password}>
            Repeat password
          </FormLabel>
          <FormField
            type="password"
            name="confirm_password"
            maxLength={20}
            validationError={errors.confirm_password}
            onChange={onNewPaswChange}
            ref={register({
              validate: (value) =>
                value === watch("new_password") && value.length !== 0,
            })}
          />
          {newPaswError && <ErrorMessage>{newPaswError}</ErrorMessage>}
          {errors.confirm_password && (
            <SignUpConfirmPasswordValidation val={watch("confirm_password")} />
          )}
        </FormFieldContainer>

        <ButtonsContainer>
          <SubmitButton type="submit" value="Change password" />
          <CancelButton onClick={onCancelButtonClick}>
            <CancelButtonText>Cancel</CancelButtonText>
          </CancelButton>
        </ButtonsContainer>
      </PasswordForm>
    );
  };

  return (
    <>
      <Header isSignedIn />
      <PageWrapper>
        <BackLink to={ROUTES.accountSettings}>
          Back to Account Settings
        </BackLink>
        <PageTitle>Change password</PageTitle>
        <SeparateLine />
        {renderForm()}
      </PageWrapper>
    </>
  );
};
