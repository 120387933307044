import styled from "styled-components";

export const ModalHeader = styled.h2`
  height: 36px;
  width: 312px;
  color: #00295b;
  font-family: "Avenir Next";
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
  text-align: center;
  margin-top: 55px;
`;

export const ModalBody = styled.p`
  height: 44px;
  width: 327px;
  color: #00295b;
  font-family: "Avenir Next";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin: 14px 0 26px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const SubmitButton = styled.button`
  height: 46px;
  width: 164px;
  border-radius: 6px;
  background-color: #00d6c2;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  border: none;
  outline: none;
  margin-left: 13px;
  cursor: pointer;
`;

export const SubmitButtonText = styled.span`
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  color: #ffffff;
`;

export const CancelButton = styled.button`
  box-sizing: border-box;
  background-color: #ffffff;
  height: 48px;
  width: 151px;
  border: 2px solid #b4b3b3;
  border-radius: 6px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  outline: none;
  cursor: pointer;
`;

export const CancelButtonText = styled.span`
  height: 22px;
  width: 135px;
  color: #626262;
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
`;
